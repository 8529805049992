
import { Component, Prop, Vue } from "vue-property-decorator";
export type HomeTabContentType = {
  bigImg: string;
  phoneImg: string;
  lateralImg?: string;
  title: string;
  txt: string;
  code: string;
};
@Component
export default class HomeTabContent extends Vue {
  @Prop() data!: HomeTabContentType;
  isShow = false;
}
