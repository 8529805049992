export default 
{
  // enginedemo
  "引擎案例": "引擎案例",
  "以下案例请使用微信扫码体验": "以下案例请使用微信扫码体验",
  "3D案例": "3D案例",
  "2D案例": "2D案例",
  "微信扫码体验": "微信扫码体验",
  "全民枪神边境王者": "全民枪神边境王者",
  "这是一款FPS竞技射击的重度H5游戏，完美传承了经典枪战品质和玩法，全3D视角，自由控制移动射击，真人对战体验。": "这是一款FPS竞技射击的重度H5游戏，完美传承了经典枪战品质和玩法，全3D视角，自由控制移动射击，真人对战体验。",
  "第7装甲师": "第7装甲师",
  "高度还原现代战争，逼真至极！酷炫合击战斗画面，全屏燃爆！四国阵营相杀相克，实时真人PVP，战力飙升傲视全服，军衔成长号令三军,坐享权力巅峰！探索战争电影里才有的史诗剧情，消灭恐怖势力，维护世界和平！": "高度还原现代战争，逼真至极！酷炫合击战斗画面，全屏燃爆！四国阵营相杀相克，实时真人PVP，战力飙升傲视全服，军衔成长号令三军,坐享权力巅峰！探索战争电影里才有的史诗剧情，消灭恐怖势力，维护世界和平！",
  "穿越火线-枪战王者": "穿越火线-枪战王者",
  "穿越火线（Cross Fire，简称CF）是经典的知名游戏IP，由腾讯采用LayaAir 3D引擎研发，2020年移植到微信小游戏平台的CF正版FPS手游。": "穿越火线（Cross Fire，简称CF）是经典的知名游戏IP，由腾讯采用LayaAir 3D引擎研发，2020年移植到微信小游戏平台的CF正版FPS手游。",
  "王者别嚣张": "王者别嚣张",
  "这是一款全新的3D MOBA竞技对决手游。公平5V5竞技手游，精彩的对局玩法，爽快的竞技过程，极速匹配为你找寻实力相当的对手同场竞技，享受刺激精彩的冒险挑战，在一场场的对局中收获属于你的胜利荣耀！": "这是一款全新的3D MOBA竞技对决手游。公平5V5竞技手游，精彩的对局玩法，爽快的竞技过程，极速匹配为你找寻实力相当的对手同场竞技，享受刺激精彩的冒险挑战，在一场场的对局中收获属于你的胜利荣耀！",
  "300大作战": "300大作战",
  "这是一款二次元3D MOBA游戏，5v5公平竞技！皮肤无属性，战斗无符文，实时跨区匹配，意想不到的作战模式，指尖爽快操作，极致战斗体验！匠心绘制精美立绘，可爱小姐姐陪你上分，全新娱乐模式，随机道具，惊喜连连，更有7V7神器战场，极限养成，酣畅淋漓！": "这是一款二次元3D MOBA游戏，5v5公平竞技！皮肤无属性，战斗无符文，实时跨区匹配，意想不到的作战模式，指尖爽快操作，极致战斗体验！匠心绘制精美立绘，可爱小姐姐陪你上分，全新娱乐模式，随机道具，惊喜连连，更有7V7神器战场，极限养成，酣畅淋漓！",
  "我的大刀四十米": "我的大刀四十米",
  "我的大刀四十米是一款热门榜单上的爆款竞技游戏，玩家将在这里上演激情刀战，只有以一人之力击败所有人才能称之为真正的强者。": "我的大刀四十米是一款热门榜单上的爆款竞技游戏，玩家将在这里上演激情刀战，只有以一人之力击败所有人才能称之为真正的强者。",
  "拇指射箭": "拇指射箭",
  "一款射箭类休闲竞技游戏。玩家将扮演一位初级射箭运动爱好者，不断挑战更高难度的箭靶来提升自己的射箭技术，慢慢变成一位技艺精湛的射箭大师。": "一款射箭类休闲竞技游戏。玩家将扮演一位初级射箭运动爱好者，不断挑战更高难度的箭靶来提升自己的射箭技术，慢慢变成一位技艺精湛的射箭大师。",
  "跳舞的线": "跳舞的线",
  "这是一款魔性又虐手的音乐跑酷游戏，玩家不仅需要用双眼来观察障碍物与陷阱，更需要用双耳聆听节奏，旋律与节奏会帮助玩家走得更远。": "这是一款魔性又虐手的音乐跑酷游戏，玩家不仅需要用双眼来观察障碍物与陷阱，更需要用双耳聆听节奏，旋律与节奏会帮助玩家走得更远。",
  "画将军": "画将军",
  "画将军是一款热血的竞技策略战争游戏，玩家动动画笔，就可以操控战场，不断挑战新的关卡。": "画将军是一款热血的竞技策略战争游戏，玩家动动画笔，就可以操控战场，不断挑战新的关卡。",
  "街头扣篮王": "街头扣篮王",
  "街头扣篮王是热门的微信创意小游戏，Q版风格打造的篮球休闲游戏,，3v3的篮球对抗赛，暴爽的扣篮、过人、抢断、投篮等游戏体验。": "街头扣篮王是热门的微信创意小游戏，Q版风格打造的篮球休闲游戏,，3v3的篮球对抗赛，暴爽的扣篮、过人、抢断、投篮等游戏体验。",
  "Parkour Race": "Parkour Race",
  "跑酷竞赛是由Madbox工作室开发的3D跑酷游戏。发布于全球知名的H5游戏网站poki，受到到热门推荐。（tips:有上架poki平台的开发者可与Layabox商务联系）": "跑酷竞赛是由Madbox工作室开发的3D跑酷游戏。发布于全球知名的H5游戏网站poki，受到到热门推荐。（tips:有上架poki平台的开发者可与Layabox商务联系）",
  "天空跑酷3D": "天空跑酷3D",
  "是模拟极限运动高空落差跳玩法的跑酷游戏，多个橡皮人同场竞技，在体验惊险刺激的跑酷游戏同时，去赢得第一名。": "是模拟极限运动高空落差跳玩法的跑酷游戏，多个橡皮人同场竞技，在体验惊险刺激的跑酷游戏同时，去赢得第一名。",
  "滑板冲冲冲": "滑板冲冲冲",
  "是在U池赛道中滑板跑酷的休闲竞速游戏，该游戏的动作细节真实模拟了现实中滑板跑酷的原型，可玩性超赞。": "是在U池赛道中滑板跑酷的休闲竞速游戏，该游戏的动作细节真实模拟了现实中滑板跑酷的原型，可玩性超赞。",
  "滚动的天空": "滚动的天空",
  "是非常考验反应力的休闲跑酷游戏，游戏中不仅关卡场景丰富华丽，每个场景中的元素也非常丰富。": "是非常考验反应力的休闲跑酷游戏，游戏中不仅关卡场景丰富华丽，每个场景中的元素也非常丰富。",
  "指尖乐动": "指尖乐动",
  "是标杆级的小球跳跃3D游戏，时尚潮流的音乐配上精美的游戏场景，动感的节奏，欢快的跳跃，可玩性超赞。": "是标杆级的小球跳跃3D游戏，时尚潮流的音乐配上精美的游戏场景，动感的节奏，欢快的跳跃，可玩性超赞。",
  "迷你跑酷": "迷你跑酷",
  "是3D立方体的格子滚动跑酷游戏，左右手各控制一个方向，每次点击前要做好预判，脑中规划好行走路径。": "是3D立方体的格子滚动跑酷游戏，左右手各控制一个方向，每次点击前要做好预判，脑中规划好行走路径。",
  "水上乐园滑梯竞速": "水上乐园滑梯竞速",
  "是模拟了现实中水上乐园的滑梯玩法，逼真的体验，丰富的皮肤、还有道具合成玩法、无尽模式玩法等。": "是模拟了现实中水上乐园的滑梯玩法，逼真的体验，丰富的皮肤、还有道具合成玩法、无尽模式玩法等。",
  "沙滩大作战": "沙滩大作战",
  "这是一款以抢占高地的炮台射击得分的休闲对战小游戏。玩家控制的角色先要躲避炮台上敌人发射的滚石炮弹。冲上炮台后射杀其它要占领炮台的敌人可获得积分。": "这是一款以抢占高地的炮台射击得分的休闲对战小游戏。玩家控制的角色先要躲避炮台上敌人发射的滚石炮弹。冲上炮台后射杀其它要占领炮台的敌人可获得积分。",
  "王牌机师3D": "王牌机师3D",
  "这是一款3D机甲飞机空战游戏 ，玩家通过火控雷达来锁定敌机，并同时发射导弹击溃敌机来完成闯关条件。面对敌方的导弹可以通过左右侧飞或者翻转来躲避。": "这是一款3D机甲飞机空战游戏 ，玩家通过火控雷达来锁定敌机，并同时发射导弹击溃敌机来完成闯关条件。面对敌方的导弹可以通过左右侧飞或者翻转来躲避。",
  "一炮到底3D": "一炮到底3D",
  "这是一款物理撞击的3D射击游戏。玩家点哪里，大炮就射向哪里，射出的炮弹与各种形状的物体发生碰撞，将其撞碎或撞出板面，完成闯关目标。": "这是一款物理撞击的3D射击游戏。玩家点哪里，大炮就射向哪里，射出的炮弹与各种形状的物体发生碰撞，将其撞碎或撞出板面，完成闯关目标。",
  "腾讯桌球": "腾讯桌球",
  "是腾讯光子工作室研发的一款基于真实玩家在线匹配的竞技对战游戏，运用了LayaAir引擎的3D技术，真实还原了桌球的质感与运动中的球感，一经推出，深受广大玩家的喜爱。": "是腾讯光子工作室研发的一款基于真实玩家在线匹配的竞技对战游戏，运用了LayaAir引擎的3D技术，真实还原了桌球的质感与运动中的球感，一经推出，深受广大玩家的喜爱。",
  "荒野大淘金": "荒野大淘金",
  "多人同屏竞技的3D捕鱼玩法，荒野丛林的主题，畅快的野兽暴击，绚丽的特效，与众不同的体验，值得一试。": "多人同屏竞技的3D捕鱼玩法，荒野丛林的主题，畅快的野兽暴击，绚丽的特效，与众不同的体验，值得一试。",
  "楼梯大作战": "楼梯大作战",
  "《楼梯大作战》是一款3D的微信休闲益智小游戏，以楼梯为背景，用户通过手指简单的左右移动控制球的跳动方向来躲避台阶上的障碍物，以及拾取钻石，超强的3D视角，让游戏更加的刺激。": "《楼梯大作战》是一款3D的微信休闲益智小游戏，以楼梯为背景，用户通过手指简单的左右移动控制球的跳动方向来躲避台阶上的障碍物，以及拾取钻石，超强的3D视角，让游戏更加的刺激。",
  "车轮压一压": "车轮压一压",
  "是一款减压的物理运动小游戏，让巨大的车轮碾压一切障碍物体吧。": "是一款减压的物理运动小游戏，让巨大的车轮碾压一切障碍物体吧。",
  "叠高跑圈圈": "叠高跑圈圈",
  "是一款轻松有趣的叠方块跑酷小游戏，叠的越高，越容易安全过关。": "是一款轻松有趣的叠方块跑酷小游戏，叠的越高，越容易安全过关。",
  "给脚就跑": "给脚就跑",
  "是一款画线跑酷小游戏，画的线是什么形状，腿就张什么样，合理的形状更加方便你通过关卡障碍。": "是一款画线跑酷小游戏，画的线是什么形状，腿就张什么样，合理的形状更加方便你通过关卡障碍。",
  "机械球快跑": "机械球快跑",
  "全球风靡的足球游戏，点球大战，等你来挑战。": "全球风靡的足球游戏，点球大战，等你来挑战。",
  "大天使之剑H5": "大天使之剑H5",
  "全新交互玩法，再现经典奇迹MU魔幻潮流，全新动态野外BOSS概念，野外探险、击杀BOSS、打怪掉宝，现在是时候来一场痛快野外厮杀了！": "全新交互玩法，再现经典奇迹MU魔幻潮流，全新动态野外BOSS概念，野外探险、击杀BOSS、打怪掉宝，现在是时候来一场痛快野外厮杀了！",
  "消灭病毒": "消灭病毒",
  "一款火爆各平台的休闲躲避射击游戏，玩家可以使用不同的武器、升级这些武器和日常收益，来消灭各种病毒。": "一款火爆各平台的休闲躲避射击游戏，玩家可以使用不同的武器、升级这些武器和日常收益，来消灭各种病毒。",
  "格斗战机": "格斗战机",
  "一款有着二战写实风格的飞机格斗空战游戏，战机真实丰富，战场逼真还原二战的战场风格，强烈推荐体验。": "一款有着二战写实风格的飞机格斗空战游戏，战机真实丰富，战场逼真还原二战的战场风格，强烈推荐体验。",
  "天天打怪兽": "天天打怪兽",
  "一款飞行射击游戏，玩家通过操控自己的战机躲避怪兽的子弹攻击，自己飞机发射子弹攻击怪兽。": "一款飞行射击游戏，玩家通过操控自己的战机躲避怪兽的子弹攻击，自己飞机发射子弹攻击怪兽。",
  "三国一统天下": "三国一统天下",
  "一款以三国历史为背景的策略性游戏，带你进入正统的三国世界！排兵布阵、历史战役、挥兵战场。": "一款以三国历史为背景的策略性游戏，带你进入正统的三国世界！排兵布阵、历史战役、挥兵战场。",
  "欢乐小飞机Q版": "欢乐小飞机Q版",
  "经典的飞行射击游戏，像素画风，回忆杀雷电、1945、武装飞鸟、战国之刃！": "经典的飞行射击游戏，像素画风，回忆杀雷电、1945、武装飞鸟、战国之刃！",
  "全民大乐斗": "全民大乐斗",
  "《全民大乐斗》是腾讯魔镜工作室自研的回合制游戏。": "《全民大乐斗》是腾讯魔镜工作室自研的回合制游戏。",
  "怼怼三国": "怼怼三国",
  "一款类皇室战争的横版H5策略竞技游戏，玩家将通过竞技战斗收集武将、小队及法术卡牌，搭配合适自己策略与其他玩家进行战斗。": "一款类皇室战争的横版H5策略竞技游戏，玩家将通过竞技战斗收集武将、小队及法术卡牌，搭配合适自己策略与其他玩家进行战斗。",
  "切水果王者版": "切水果王者版",
  "开局一把刀，粉碎所有水果！这是一款休闲的切水果游戏，通过飞镖等武器命中全部的水果来获得爆浆的快感！": "开局一把刀，粉碎所有水果！这是一款休闲的切水果游戏，通过飞镖等武器命中全部的水果来获得爆浆的快感！",

  // engineDownload
  "我接受条款": "我接受条款",
  "我确认我或我的公司愿意遵守LayaAir引擎的以下使用条件，否则将下架线上产品直到停止引擎的使用，条款如下：": "我确认我或我的公司愿意遵守LayaAir引擎的以下使用条件，否则将下架线上产品直到停止引擎的使用，条款如下：",
  "Layabox引擎软件使用条款全文": "Layabox引擎软件使用条款全文",
  "在游戏加载页或首页的任意可见位置注明：Powered by LayaAir Engine": "在游戏加载页或首页的任意可见位置注明：Powered by LayaAir Engine",
  "如果想去除引擎相关标识，请联系Layabox商务合作对接人进行授权。": "如果想去除引擎相关标识，请联系Layabox商务合作对接人进行授权。",
  "立即下载": "立即下载",
  "更新时间：": "更新时间：",

  // engineIntroduction
  "引擎简介": "引擎简介",
  "招聘信息": "招聘信息",
  "联系方式": "联系方式",
  "LayaAir是Layabox旗下HTML5开源引擎，发布于2016年，拥有成熟的引擎架构与配套工具链，一次开发，全平台（HTML5小游戏Native APP）发布。广泛应用于2D与3D游戏、应用软件、广告与营销、教育、智慧城市、元宇宙等众多领域的开发。已拥有开发者超100万，服务于腾讯、阿里巴巴、网易、三七互娱、完美世界、电魂网络、光宇游戏、汤姆猫、猎豹移动、第七大道、掌趣、商汤科技、精锐教育等众多使用LayaAir的知名企业，在3D小游戏领域的引擎市场始终保持行业龙头地位。": "LayaAir是Layabox旗下HTML5开源引擎，发布于2016年，拥有成熟的引擎架构与配套工具链，一次开发，全平台（HTML5小游戏Native APP）发布。广泛应用于2D与3D游戏、应用软件、广告与营销、教育、智慧城市、元宇宙等众多领域的开发。已拥有开发者超100万，服务于腾讯、阿里巴巴、网易、三七互娱、完美世界、电魂网络、光宇游戏、汤姆猫、猎豹移动、第七大道、掌趣、商汤科技、精锐教育等众多使用LayaAir的知名企业，在3D小游戏领域的引擎市场始终保持行业龙头地位。",
  "2023年10月，LayaAir引擎团队与掌趣科技合作，发布LayaAir 3.1版本——正式成为一款具备AI生产力的游戏研发引擎，全面拥抱AIGC（人工智能生成内容）。引擎支持AI创作生成、编辑器的AI控制、引擎技术AI客服等AI插件能力。未来，LayaAir将在保障在国产Web 3D引擎渲染能力持续领先的基础上，继续深入与掌趣科技的合作，大力发展AIGC与3D引擎的结合，定位“最易用的AIGC引擎生态”。": "2023年10月，LayaAir引擎团队与掌趣科技合作，发布LayaAir 3.1版本——正式成为一款具备AI生产力的游戏研发引擎，全面拥抱AIGC（人工智能生成内容）。引擎支持AI创作生成、编辑器的AI控制、引擎技术AI客服等AI插件能力。未来，LayaAir将在保障在国产Web 3D引擎渲染能力持续领先的基础上，继续深入与掌趣科技的合作，大力发展AIGC与3D引擎的结合，定位“最易用的AIGC引擎生态”。",
  "招贤纳士": "招贤纳士",
  "JOB POSITION": "JOB POSITION",
  "Layabox是HTML5引擎技术提供商与技术服务商。欢迎各种人才的加入，这里给您最大的发挥空间，一起共创辉煌": "Layabox是HTML5引擎技术提供商与技术服务商。欢迎各种人才的加入，这里给您最大的发挥空间，一起共创辉煌",
  "请投简历到HR邮箱: hr@layabox.com 期待优秀的你，共同将梦想写进未来！邮件投递请主题标注官网投递": "请投简历到HR邮箱: hr@layabox.com 期待优秀的你，共同将梦想写进未来！邮件投递请主题标注官网投递",
  "引擎开发工程师": "引擎开发工程师",
  "岗位职责：": "岗位职责：",
  "1、负责LayaNative开发及维护工作；": "1、负责LayaNative开发及维护工作；",
  "2、开发跨平台自动化发布流程；": "2、开发跨平台自动化发布流程；",
  "3、负责引擎打包底层开发，C++方向。": "3、负责引擎打包底层开发，C++方向。",
  "任职要求：": "任职要求：",
  "1、良好的数学和算法基础，热爱程序工作；": "1、良好的数学和算法基础，热爱程序工作；",
  "2、优秀的C++设计、分析能力及规范的编程风格；": "2、优秀的C++设计、分析能力及规范的编程风格；",
  "3、熟悉OpenGL及相关图形接口，优秀的学习研究能力；": "3、熟悉OpenGL及相关图形接口，优秀的学习研究能力；",
  "4、熟悉软件开发流程，了解软件打包及更新机制；": "4、熟悉软件开发流程，了解软件打包及更新机制；",
  "5、擅长自动化开发工具,熟悉多平台（WIN/MAC）开发优先；": "5、擅长自动化开发工具,熟悉多平台（WIN/MAC）开发优先；",
  "6、具备自我管理和驱动能力，能够独立设定工作的优先次序。": "6、具备自我管理和驱动能力，能够独立设定工作的优先次序。",
  "城市：北京": "城市：北京",
  "3D渲染软件工程师": "3D渲染软件工程师",
  "1、负责网页端，移动端的3D渲染软件研究、开发与维护；": "1、负责网页端，移动端的3D渲染软件研究、开发与维护；",
  "2、配合策划、美术、项目等各部门工作，保证研发工作正常进行。": "2、配合策划、美术、项目等各部门工作，保证研发工作正常进行。",
  "1、熟悉OpenGL, WebGL, glsl，擅长网页端，移动端的3D开发；": "1、熟悉OpenGL, WebGL, glsl，擅长网页端，移动端的3D开发；",
  "2、熟悉JavaScript/TypeScript语言，良好的OOP编程思想；": "2、熟悉JavaScript/TypeScript语言，良好的OOP编程思想；",
  "3、热爱研发性质工作，对软件的优化和性能优化有一定研究；": "3、热爱研发性质工作，对软件的优化和性能优化有一定研究；",
  "4、具有二年以上3D游戏类软件的开发经验；": "4、具有二年以上3D游戏类软件的开发经验；",
  "5、有团队合作精神和快速执行力，能充分理解设计意图并尽可能用最优方案完成；": "5、有团队合作精神和快速执行力，能充分理解设计意图并尽可能用最优方案完成；",
  "6、热衷于技术钻研，工作踏实诚恳，认真负责，具有主动性，有良好的抗压能力。": "6、热衷于技术钻研，工作踏实诚恳，认真负责，具有主动性，有良好的抗压能力。",
  "前端开发工程师": "前端开发工程师",
  "1、HTML5游戏前端开发和维护工作；": "1、HTML5游戏前端开发和维护工作；",
  "2、与策划、美术、后端以及运营人员讨论开发需求及技术实现细节；": "2、与策划、美术、后端以及运营人员讨论开发需求及技术实现细节；",
  "3、完成相关的技术文档编写。": "3、完成相关的技术文档编写。",
  "1、熟悉主流H5游戏引擎，使用开发过一款以上游戏；": "1、熟悉主流H5游戏引擎，使用开发过一款以上游戏；",
  "2、熟悉JS、TS、AS3语言，良好的OOP编程思想；": "2、熟悉JS、TS、AS3语言，良好的OOP编程思想；",
  "3、熟悉 WebSocket 和 HTTP 等网络协议，熟悉JSON、ProtoBuf 等数据协议；": "3、熟悉 WebSocket 和 HTTP 等网络协议，熟悉JSON、ProtoBuf 等数据协议；",
  "3、有良好的代码书写、注释和单元测试习惯，熟练运用多种软件设计模式；": "3、有良好的代码书写、注释和单元测试习惯，熟练运用多种软件设计模式；",
  "4、具备良好的沟通合作技巧，较强的责任心及团队合作精神。": "4、具备良好的沟通合作技巧，较强的责任心及团队合作精神。",
  "城市：北京/太原": "城市：北京/太原",
  "JAVA后端开发工程师": "JAVA后端开发工程师",
  "1、参与帧同步、时间同步服务器端引擎的开发；": "1、参与帧同步、时间同步服务器端引擎的开发；",
  "2、服务端业务模块的开发；": "2、服务端业务模块的开发；",
  "3、根据产品需求进行程序设计，并编写详细设计文档。": "3、根据产品需求进行程序设计，并编写详细设计文档。",
  "职位要求：": "职位要求：",
  "1、5年以上相关开发经验；": "1、5年以上相关开发经验；",
  "2、精通 JAVA、精通 springboot、springcloud 框架；": "2、精通 JAVA、精通 springboot、springcloud 框架；",
  "4、精通网络通讯原理；": "4、精通网络通讯原理；",
  "5、精通多线程的程序设计、开发；": "5、精通多线程的程序设计、开发；",
  "6、熟练掌握常用的设计模式；": "6、熟练掌握常用的设计模式；",
  "7、熟悉Mysql数据库、redis缓存、消息队列、Elasticsearch的开发使用；": "7、熟悉Mysql数据库、redis缓存、消息队列、Elasticsearch的开发使用；",
  "8、为人踏实，拥有严谨的工作态度，善于学习新知识；": "8、为人踏实，拥有严谨的工作态度，善于学习新知识；",
  "7、良好的团队合作精神，善于沟通，有高度责任感，能够承受较大的工作压力；": "7、良好的团队合作精神，善于沟通，有高度责任感，能够承受较大的工作压力；",
  "Web服务器开发工程师": "Web服务器开发工程师",
  "1、负责后台业务模块开发，后台管理相关页面的开发；": "1、负责后台业务模块开发，后台管理相关页面的开发；",
  "2、与产品经理沟通产品需求，把握需求的合理性和优先级；": "2、与产品经理沟通产品需求，把握需求的合理性和优先级；",
  "1. 3年以上相关开发经验；": "1. 3年以上相关开发经验；",
  "2、精通 PHP，至少精通一个PHP开发框架；": "2、精通 PHP，至少精通一个PHP开发框架；",
  "3、精通 MySQL 的开发、调优；": "3、精通 MySQL 的开发、调优；",
  "4、熟悉高并发网站开发，分布式系统开发，能对分布式技术进行合理应用；": "4、熟悉高并发网站开发，分布式系统开发，能对分布式技术进行合理应用；",
  "5、熟悉 Elasticsearch、消息队列、MongoDb、Redis；": "5、熟悉 Elasticsearch、消息队列、MongoDb、Redis；",
  "6、熟悉掌握HTML5，CSS，Javascript，JQuery，AJAX，JSON , VUE等web页面技术；": "6、熟悉掌握HTML5，CSS，Javascript，JQuery，AJAX，JSON , VUE等web页面技术；",
  "7、为人踏实，拥有严谨的工作态度，善于学习新知识；": "7、为人踏实，拥有严谨的工作态度，善于学习新知识；",
  "8、良好的团队合作精神，善于沟通，有高度责任感，能够承受较大的工作压力。": "8、良好的团队合作精神，善于沟通，有高度责任感，能够承受较大的工作压力。",
  "项目经理": "项目经理",
  "1. 需求分析：与外部与内部的相关方进行沟通、需求分析、合理性评估；": "1. 需求分析：与外部与内部的相关方进行沟通、需求分析、合理性评估；",
  "2. 项目排期：基于合作方需求、内部资源协调进行项目排期；": "2. 项目排期：基于合作方需求、内部资源协调进行项目排期；",
  "3. 风险把控：基于内部与外部的变化，项目进度的梳理，及时沟通协调，调整方案化解问题；": "3. 风险把控：基于内部与外部的变化，项目进度的梳理，及时沟通协调，调整方案化解问题；",
  "4. 质量把控：把控产品需求的颗粒度、美术资源的质量、技术交付质量、完整体验项目流程，对项目质量负责；": "4. 质量把控：把控产品需求的颗粒度、美术资源的质量、技术交付质量、完整体验项目流程，对项目质量负责；",
  "5. 过程管理、最终交付：把控项目不同环节、不同部门之间的任务并行串行时的衔接问题，提醒和督促相关环节，最终给出符合质量要求的项目交付。": "5. 过程管理、最终交付：把控项目不同环节、不同部门之间的任务并行串行时的衔接问题，提醒和督促相关环节，最终给出符合质量要求的项目交付。",
  "1. 追求极致效率，快速推进业务；发现团队的模糊地带能及时推动制定相关的业务标准流程；": "1. 追求极致效率，快速推进业务；发现团队的模糊地带能及时推动制定相关的业务标准流程；",
  "2. 在调研分析、任务拆解上有多许多经验与积累；": "2. 在调研分析、任务拆解上有多许多经验与积累；",
  "3. 拥有良好的沟通协调能力，处理协调过与合作方、内部之间的分歧与矛盾；": "3. 拥有良好的沟通协调能力，处理协调过与合作方、内部之间的分歧与矛盾；",
  "4. 在项目管理领域拥有3-5年经验，参与过项目管理的全流程，从0到1完成项目交付；": "4. 在项目管理领域拥有3-5年经验，参与过项目管理的全流程，从0到1完成项目交付；",
  "5. 本科及以上学历，拥有PMP证书，项目管理相关证书的优先；": "5. 本科及以上学历，拥有PMP证书，项目管理相关证书的优先；",
  "Contact Information": "Contact Information",
  "地址：北京市大兴区盛坊路元宇宙大厦9层": "地址：北京市大兴区盛坊路元宇宙大厦9层",
  "邮箱：weiss@layabox.com、bd@layabox.com": "邮箱：weiss@layabox.com、bd@layabox.com",
  "电话：18611806735、010-56277796": "电话：18611806735、010-56277796",
  "公众号二维码": "公众号二维码",
  "城市：北京/成都/太原": "城市：北京/成都/太原",
  "城市：成都/北京": "城市：成都/北京",
  "Layaverse美术总监": "Layaverse美术总监",
  "1、了解策划意图，依据场景设计流程跟进后续流程，参与游戏最终效果审核；": "1、了解策划意图，依据场景设计流程跟进后续流程，参与游戏最终效果审核；",
  "2、与项目组美术、程序与策划人员深入沟通，准确实现项目开发需求；": "2、与项目组美术、程序与策划人员深入沟通，准确实现项目开发需求；",
  "3、负责项目中场景规划及原画设计；": "3、负责项目中场景规划及原画设计；",
  "4、负责场景概念气氛设计和场景配图；": "4、负责场景概念气氛设计和场景配图；",
  "5、负责创意宣传画绘制。": "5、负责创意宣传画绘制。",
  "1、5年以上游戏原画工作经验，美术专业优先，美术基本功扎实。专业能力突出，审美能力强；": "1、5年以上游戏原画工作经验，美术专业优先，美术基本功扎实。专业能力突出，审美能力强；",
  "2、学习能力强；做事认真；有合作意识；容易接受新事物，有创新精神；": "2、学习能力强；做事认真；有合作意识；容易接受新事物，有创新精神；",
  "3、有建筑设计，写实、科幻类、沙盒类项目经验者优先；": "3、有建筑设计，写实、科幻类、沙盒类项目经验者优先；",
  "4、精通建模、设计等相关软件者优先。": "4、精通建模、设计等相关软件者优先。",
  "城市：北京/成都": "城市：北京/成都",
  "创意概念设计师/资深原画设计师": "创意概念设计师/资深原画设计师",
  "1、负责项目世界观搭建、概念设计、地图建筑规划，功能区划分；": "1、负责项目世界观搭建、概念设计、地图建筑规划，功能区划分；",
  "2、了解产品意图，能够独立完成高品质场景气氛图和场景配图，并跟进后续流程参与实现效果落地；": "2、了解产品意图，能够独立完成高品质场景气氛图和场景配图，并跟进后续流程参与实现效果落地；",
  "3、能够通过与需求方的交流，理解意图，提出独到的设计方案。": "3、能够通过与需求方的交流，理解意图，提出独到的设计方案。",
  "1、美术专业科班出身、拥有国际化视野、三年以上原画设计工作经验；": "1、美术专业科班出身、拥有国际化视野、三年以上原画设计工作经验；",
  "2、较强的绘画功底、深刻了解主界面，城市的规划等优先次序；": "2、较强的绘画功底、深刻了解主界面，城市的规划等优先次序；",
  "3、热爱新事物、喜欢创新与探索、视野宽泛、对高品质游戏拥有敏锐观察力、设计新颖，生活化并且趣味性；": "3、热爱新事物、喜欢创新与探索、视野宽泛、对高品质游戏拥有敏锐观察力、设计新颖，生活化并且趣味性；",
  "4、善于沟通、积极主动、有很好的团队意识和换位思考能力；": "4、善于沟通、积极主动、有很好的团队意识和换位思考能力；",
  "5、有过科幻游戏、沙盘规划制作经验者优先。": "5、有过科幻游戏、沙盘规划制作经验者优先。",
  "商务合作微信": "商务合作微信",
  "地址：北京市海淀区中关村壹号C2座": "地址：北京市海淀区中关村壹号C2座",
  "城市：成都": "城市：成都",

  // 引擎动态
  "引擎动态": "引擎动态",
  "上一页": "上一页",
  "下一页": "下一页",

  // productpage_LayaAirEngine
  "LayaAir 引擎": "LayaAir 引擎",
  "LayaAir IDE": "LayaAir IDE",
  "LayaAir Native": "LayaAir Native",
  "LayaAir 技术服务": "LayaAir 技术服务",
  "引擎发展简介": "引擎发展简介",
  "Layabox是北京蓝亚盒子科技有限公司打造的引擎服务商与元宇宙服务商企业品牌。": "Layabox是北京蓝亚盒子科技有限公司打造的引擎服务商与元宇宙服务商企业品牌。",
  "LayaAir引擎是Layabox旗下的全平台3D引擎产品。": "LayaAir引擎是Layabox旗下的全平台3D引擎产品。",
  "LayaAir引擎1.0正式版，首次发公开发布于2016年6月30日，包括2D与3D引擎功能，自带UI编辑器，支持一次开发全平台发布。": "LayaAir引擎1.0正式版，首次发公开发布于2016年6月30日，包括2D与3D引擎功能，自带UI编辑器，支持一次开发全平台发布。",
  "2016年底，基于LayaAir引擎的首款商业3D ARPG游戏《无尽骑士》上线，这也是基于webGL图形API的全球首款3D ARPG商业级大作。同时也标志着LayaAir引擎已经达到商业级3D游戏研发的成熟度。": "2016年底，基于LayaAir引擎的首款商业3D ARPG游戏《无尽骑士》上线，这也是基于webGL图形API的全球首款3D ARPG商业级大作。同时也标志着LayaAir引擎已经达到商业级3D游戏研发的成熟度。",
  "2017年，大量基于LayaAir2D与3D的大型游戏代表作出现。": "2017年，大量基于LayaAir2D与3D的大型游戏代表作出现。",
  "在2D引擎案例方面：8月3日，腾讯旗下魔镜工作室采用LayaAir引擎自研的格斗类HTML5游戏《全民大乐斗》在微信、手机QQ两大平台的安卓版本公测上线。该游戏标志着LayaAir引擎的成熟度已得到顶级游戏团队的认可，并通过产品验证了引擎的成熟度。": "在2D引擎案例方面：8月3日，腾讯旗下魔镜工作室采用LayaAir引擎自研的格斗类HTML5游戏《全民大乐斗》在微信、手机QQ两大平台的安卓版本公测上线。该游戏标志着LayaAir引擎的成熟度已得到顶级游戏团队的认可，并通过产品验证了引擎的成熟度。",
  "10月22日，上线24天的《大天使之剑H5》宣告流水破亿，该游戏由上市公司三七互娱旗下团队采用LayaAir引擎研发，web端链接、安卓端APP、iOS端APP同时发布。2018年，《大天使之剑H5》又发布了微信小游戏版本。该游戏代表着LayaAir引擎的高性能与成熟，足够支撑亿级流水的2D商业大作。": "10月22日，上线24天的《大天使之剑H5》宣告流水破亿，该游戏由上市公司三七互娱旗下团队采用LayaAir引擎研发，web端链接、安卓端APP、iOS端APP同时发布。2018年，《大天使之剑H5》又发布了微信小游戏版本。该游戏代表着LayaAir引擎的高性能与成熟，足够支撑亿级流水的2D商业大作。",
  "在LayaAir引擎 3D案例方面，2017年7月21日，采用知名小说IP《雪鹰领主》3D ARPG游戏在腾讯玩吧首发，精细的原画、华丽的特效、结合爽快战斗打击感，流畅的原生级游戏性能表现，让LayaAir 3D引擎再一次进入大众视野。": "在LayaAir引擎 3D案例方面，2017年7月21日，采用知名小说IP《雪鹰领主》3D ARPG游戏在腾讯玩吧首发，精细的原画、华丽的特效、结合爽快战斗打击感，流畅的原生级游戏性能表现，让LayaAir 3D引擎再一次进入大众视野。",
  "2018年，是小游戏爆发的元年，作为国内唯一拥有商业案例的Web3D游戏引擎。得到了广大开发者用户的追捧，用户基数直线飙升，从2017年的接近10万，到2018年底，已空破60万。": "2018年，是小游戏爆发的元年，作为国内唯一拥有商业案例的Web3D游戏引擎。得到了广大开发者用户的追捧，用户基数直线飙升，从2017年的接近10万，到2018年底，已空破60万。",
  "在引擎案例与代表作方面，2018年也呈现井喷式发展，仅可统计的LayaAir 3D引擎小游戏案例已经超过300款，例如《猪猪侠快跑》、《跳舞的线》、《全民足球》、《腾讯桌球》等等知名爆款。在2018年小游戏引擎市场中，国内引擎市场占比100%，国际引擎市场占比超过97%。": "在引擎案例与代表作方面，2018年也呈现井喷式发展，仅可统计的LayaAir 3D引擎小游戏案例已经超过300款，例如《猪猪侠快跑》、《跳舞的线》、《全民足球》、《腾讯桌球》等等知名爆款。在2018年小游戏引擎市场中，国内引擎市场占比100%，国际引擎市场占比超过97%。",
  "2018年07月05日，LayaAir 2.0开启内测，9月15日，LayaAir 2.0开启公测。基于LayaAir 引擎在3D领域的优异市场表现，以及用户的需求。LayaAir 2.0引擎决定全力投入3D引擎的研发，开启了Web3D国产引擎领跑产业的技术之路。": "2018年07月05日，LayaAir 2.0开启内测，9月15日，LayaAir 2.0开启公测。基于LayaAir 引擎在3D领域的优异市场表现，以及用户的需求。LayaAir 2.0引擎决定全力投入3D引擎的研发，开启了Web3D国产引擎领跑产业的技术之路。",
  "2019年至2022年，LayaAir 2.0始终坚定3D引擎上的极大投入，也持续占领着web3D引擎的市场龙头位置，在技术上保持着领先的优势。其间，也产生了大量2D与3D的爆款产品，例如，休闲消除游戏爆款《消灭病毒》、FPS游戏爆款《全民枪神边境王者》与《穿越火线-枪战王者》、MOBA游戏爆款《300大作战》、IO竞技游戏爆款《我的大刀四十米》、机甲PVP爆款《第7装甲师》等等。": "2019年至2022年，LayaAir 2.0始终坚定3D引擎上的极大投入，也持续占领着web3D引擎的市场龙头位置，在技术上保持着领先的优势。其间，也产生了大量2D与3D的爆款产品，例如，休闲消除游戏爆款《消灭病毒》、FPS游戏爆款《全民枪神边境王者》与《穿越火线-枪战王者》、MOBA游戏爆款《300大作战》、IO竞技游戏爆款《我的大刀四十米》、机甲PVP爆款《第7装甲师》等等。",
  "2023年6月30日，LayaAir3.0正式版推出，该版本的推出，填补了LayaAir引擎在3D编辑器上的不足，不再依赖第三方3D编辑器，从此实现了2D+3D游戏的一站式开发。建立了从引擎到工具链的完整工作流。从引擎上，不仅升级了底层架构，更是面向未来的支持了新一代的图形API--WebGPU。在Native APP层更是支持了C++的引擎算法与渲染，以及开发者自定义的C++算法。无论是引擎的设计，还是IDE的设计，都是突破性阶段的发展。": "2023年6月30日，LayaAir3.0正式版推出，该版本的推出，填补了LayaAir引擎在3D编辑器上的不足，不再依赖第三方3D编辑器，从此实现了2D+3D游戏的一站式开发。建立了从引擎到工具链的完整工作流。从引擎上，不仅升级了底层架构，更是面向未来的支持了新一代的图形API--WebGPU。在Native APP层更是支持了C++的引擎算法与渲染，以及开发者自定义的C++算法。无论是引擎的设计，还是IDE的设计，都是突破性阶段的发展。",
  "2023年11月，LayaAir引擎团队与掌趣科技合作，发布LayaAir 3.1版本——正式成为一款具备AI生产力的游戏研发引擎，全面拥抱AIGC（人工智能生成内容）。引擎支持AI创作生成、编辑器的AI控制、引擎技术AI客服等AI插件能力。未来，LayaAir将在保障在国产Web 3D引擎渲染能力持续领先的基础上，继续深入与掌趣科技的合作，大力发展AIGC与3D引擎的结合，定位“最易用的AIGC引擎生态”。": "2023年11月，LayaAir引擎团队与掌趣科技合作，发布LayaAir 3.1版本——正式成为一款具备AI生产力的游戏研发引擎，全面拥抱AIGC（人工智能生成内容）。引擎支持AI创作生成、编辑器的AI控制、引擎技术AI客服等AI插件能力。未来，LayaAir将在保障在国产Web 3D引擎渲染能力持续领先的基础上，继续深入与掌趣科技的合作，大力发展AIGC与3D引擎的结合，定位“最易用的AIGC引擎生态”。",
  "引擎代码概述": "引擎代码概述",
  "引擎代码，除了开放式的可编程的渲染管线，全平台的图形引擎架构，次世代PBR渲染流，ClusterLighting多光源技术，Forward+渲染管线，高性能并行渲染器API的接入（WebGPU）等核心基础。对于开发者而言，我们从通用、2D、3D，这三个部分来分别介绍。": "引擎代码，除了开放式的可编程的渲染管线，全平台的图形引擎架构，次世代PBR渲染流，ClusterLighting多光源技术，Forward+渲染管线，高性能并行渲染器API的接入（WebGPU）等核心基础。对于开发者而言，我们从通用、2D、3D，这三个部分来分别介绍。",
  "引擎通用部分": "引擎通用部分",
  "网络（HTTP请求、WebSocket请求）": "网络（HTTP请求、WebSocket请求）",
  "加载（可加载文本、JSON、XML、二进制、音频、视频、骨骼文件、图像文件等资源）": "加载（可加载文本、JSON、XML、二进制、音频、视频、骨骼文件、图像文件等资源）",
  "ECS组件系统（组件系统、生命周期方法）": "ECS组件系统（组件系统、生命周期方法）",
  "场景管理": "场景管理",
  "事件（派发、侦听，捕获）": "事件（派发、侦听，捕获）",
  "交互（鼠标、键盘、屏幕touch、VR手柄）": "交互（鼠标、键盘、屏幕touch、VR手柄）",
  "数学方法": "数学方法",
  "多媒体播放（音频、视频）": "多媒体播放（音频、视频）",
  "缓动": "缓动",
  "浏览器接口（封装常用的浏览器window功能、调用浏览器window功能）": "浏览器接口（封装常用的浏览器window功能、调用浏览器window功能）",
  "设备接口（陀螺仪、加速计、地理位置）": "设备接口（陀螺仪、加速计、地理位置）",
  "节点": "节点",
  "屏幕适配": "屏幕适配",
  "小游戏适配（微信、手机QQ、字节跳动小游戏、OPPO、vivo、……等）": "小游戏适配（微信、手机QQ、字节跳动小游戏、OPPO、vivo、……等）",
  "2D引擎": "2D引擎",
  "2D精灵（2D基础显示对象与容器）": "2D精灵（2D基础显示对象与容器）",
  "2D视图（视窗、弹窗）": "2D视图（视窗、弹窗）",
  "2D动画（图集动画、逐帧动画、缓动动画、时间轴动画、龙骨动画、spine动画）": "2D动画（图集动画、逐帧动画、缓动动画、时间轴动画、龙骨动画、spine动画）",
  "2D文本（基础文本、HTML文本、BitmapFont位图字体）": "2D文本（基础文本、HTML文本、BitmapFont位图字体）",
  "2D UI组件（图像、按钮、显示文本、文本输入、文本域、下拉框、多选框、单选框、单选框组、导航标签组、导航容器、位图切片、位图字体切片、垂直滚动条、水平滚动条、进度条、垂直划动条、水平划动条、取色器、基础容器、列表、树状列表、面板容器）": "2D UI组件（图像、按钮、显示文本、文本输入、文本域、下拉框、多选框、单选框、单选框组、导航标签组、导航容器、位图切片、位图字体切片、垂直滚动条、水平滚动条、进度条、垂直划动条、水平划动条、取色器、基础容器、列表、树状列表、面板容器）",
  "2D UI效果（遮罩、滤镜）": "2D UI效果（遮罩、滤镜）",
  "2D场景继承类（管理UI）": "2D场景继承类（管理UI）",
  "2D绘图（绘制矩形与圆角矩形、绘制圆形与扇形、绘制多边形、绘制线段、绘制折线、绘制曲线、绘制纹理与填充纹理）": "2D绘图（绘制矩形与圆角矩形、绘制圆形与扇形、绘制多边形、绘制线段、绘制折线、绘制曲线、绘制纹理与填充纹理）",
  "2D物理（Box2D）": "2D物理（Box2D）",
  "Tiled Map地图": "Tiled Map地图",
  "3D引擎": "3D引擎",
  "3D精灵（3D基础显示对象与容器）": "3D精灵（3D基础显示对象与容器）",
  "3D基础工具（3D坐标系、3D变换、3D数学工具、等）": "3D基础工具（3D坐标系、3D变换、3D数学工具、等）",
  "3D场景（场景管理、环境光、环境反射、场景天空、场景雾、等）": "3D场景（场景管理、环境光、环境反射、场景天空、场景雾、等）",
  "3D摄像机": "3D摄像机",
  "3D光照（方向光、点光、聚光、区域光、阴影、光效）": "3D光照（方向光、点光、聚光、区域光、阴影、光效）",
  "3D网格": "3D网格",
  "3D材质（模型材质、粒子材质、拖尾材质、天空材质）": "3D材质（模型材质、粒子材质、拖尾材质、天空材质）",
  "3D纹理": "3D纹理",
  "3D粒子系统": "3D粒子系统",
  "3D拖尾": "3D拖尾",
  "3D物理（bullet 、Cannon.js、PhysX）": "3D物理（bullet 、Cannon.js、PhysX）",
  "3D动画（刚体动画、材质动画、骨骼动画、摄像机动画、时间轴动画）": "3D动画（刚体动画、材质动画、骨骼动画、摄像机动画、时间轴动画）",
  "自定义Shader": "自定义Shader",
  "webXR": "webXR",

  // productpage_LayaAirIDE
  "IDE发展简介": "IDE发展简介",
  "IDE是Integrated Development Environment（集成开发环境）的缩写。": "IDE是Integrated Development Environment（集成开发环境）的缩写。",
  "LayaAir 1.0与2.0的 IDE，主要提供了项目的创建与管理、UI编辑、动画编辑、多平台发布与构建、综合工具包（图集打包、骨骼动画转换、纹理压缩、文件压缩等），这些2D开发的工作流。": "LayaAir 1.0与2.0的 IDE，主要提供了项目的创建与管理、UI编辑、动画编辑、多平台发布与构建、综合工具包（图集打包、骨骼动画转换、纹理压缩、文件压缩等），这些2D开发的工作流。",
  "2021年，Layabox全资收购了游戏行业知名的UI编辑器FairyGUI。并由FairyGUI的作者担任LayaAir3.0 IDE的研发负责人。": "2021年，Layabox全资收购了游戏行业知名的UI编辑器FairyGUI。并由FairyGUI的作者担任LayaAir3.0 IDE的研发负责人。",
  "LayaAir3.0 IDE 在继承了LayaAir2.0 UI编辑器核心工作流的基础上。优化了2D编辑器，增加了3D编辑器的各项功能模块，以及资源商城等生态模块。": "LayaAir3.0 IDE 在继承了LayaAir2.0 UI编辑器核心工作流的基础上。优化了2D编辑器，增加了3D编辑器的各项功能模块，以及资源商城等生态模块。",
  "LayaAir引擎从3.0的IDE开始，搭建了引擎2D与3D一站式集成开发环境，完全满足开发者使用LayaAir引擎的各种可视化开发需求。": "LayaAir引擎从3.0的IDE开始，搭建了引擎2D与3D一站式集成开发环境，完全满足开发者使用LayaAir引擎的各种可视化开发需求。",
  "IDE主要功能模块概述": "IDE主要功能模块概述",
  "IDE的通用功能": "IDE的通用功能",
  "层级面板": "层级面板",
  "项目资源面板": "项目资源面板",
  "场景面板": "场景面板",
  "构建发布面板": "构建发布面板",
  "控制台面板": "控制台面板",
  "属性设置面板": "属性设置面板",
  "预览运行面板": "预览运行面板",
  "LayaAir引擎资源商店": "LayaAir引擎资源商店",
  "LayaAir IDE插件系统": "LayaAir IDE插件系统",
  "IDE的2D功能模块": "IDE的2D功能模块",
  "UI编辑模块": "UI编辑模块",
  "2D动画编辑模块": "2D动画编辑模块",
  "2D预制体模块": "2D预制体模块",
  "2D物理编辑模块": "2D物理编辑模块",
  "2D组件模块": "2D组件模块",
  "IDE的3D功能模块": "IDE的3D功能模块",
  "3D场景编辑模块": "3D场景编辑模块",
  "3D材质编辑模块": "3D材质编辑模块",
  "3D蓝图编辑模块": "3D蓝图编辑模块",
  "3D粒子编辑模块": "3D粒子编辑模块",
  "3D物理编辑模块": "3D物理编辑模块",
  "3D动画编辑模块": "3D动画编辑模块",
  "3D烘焙模块": "3D烘焙模块",

  // productpage_LayaAirNative
  "LayaAir Native简介": "LayaAir Native简介",
  "LayaAir Native是LayaAir引擎针对移动端原生APP的开发、测试、发布的一套完整的开发解决方案。": "LayaAir Native是LayaAir引擎针对移动端原生APP的开发、测试、发布的一套完整的开发解决方案。",
  "LayaAir Native基于C++的运行时，封装了原生的图形API（例如OpenGL ES），支持LayaAir引擎产品可打包成为APP上架移动端APP平台。": "LayaAir Native基于C++的运行时，封装了原生的图形API（例如OpenGL ES），支持LayaAir引擎产品可打包成为APP上架移动端APP平台。",
  "还支持反射机制，提供开发者在原生APP上进行二次开发和渠道对接的自定义扩展。": "还支持反射机制，提供开发者在原生APP上进行二次开发和渠道对接的自定义扩展。",
  "LayaAir Native的3.0版本，更是进行了大量的重构，在面向未来，以及开发者的扩展自由度上，进一步得到了加强。": "LayaAir Native的3.0版本，更是进行了大量的重构，在面向未来，以及开发者的扩展自由度上，进一步得到了加强。",
  "例如，在渲染内核方面，除了OpenGL ES，新增支持Vulkan、Metal。": "例如，在渲染内核方面，除了OpenGL ES，新增支持Vulkan、Metal。",
  "在核心算法层里，开发者可以直接用C++的算法，直接接管替换引擎的JS算法。": "在核心算法层里，开发者可以直接用C++的算法，直接接管替换引擎的JS算法。",
  "LayaAir Native 3.0包括以下主要功能：": "LayaAir Native 3.0包括以下主要功能：",
  "LayaPlayer": "LayaPlayer",
  "LayaPlayer是LayaNative核心的部分，它是一个基于JavaScript脚本引擎 + OpenGL ES硬件加速渲染的跨平台C++引擎，通过对内存与渲染流程进行极致优化，为LayaAir引擎脱离浏览器成为APP提供独立的底层运行环境。": "LayaPlayer是LayaNative核心的部分，它是一个基于JavaScript脚本引擎 + OpenGL ES硬件加速渲染的跨平台C++引擎，通过对内存与渲染流程进行极致优化，为LayaAir引擎脱离浏览器成为APP提供独立的底层运行环境。",
  "需要注意的是，LayaPlayer不等于浏览器，仅为LayaAir引擎运行提供支撑，不支持普通web链接的运行。": "需要注意的是，LayaPlayer不等于浏览器，仅为LayaAir引擎运行提供支撑，不支持普通web链接的运行。",
  "构建工具": "构建工具",
  "构建工具可帮助开发者快速构建移动端APP项目工程，然后使用Android Studio、Eclipce、XCode 等开发工具打开->构建->运行；": "构建工具可帮助开发者快速构建移动端APP项目工程，然后使用Android Studio、Eclipce、XCode 等开发工具打开->构建->运行；",
  "反射机制": "反射机制",
  "通过反射机制，开发者可以实现JavaScript与原生语言(Android/Java 或 iOS/Objective-C)的相互调用，通过反射机制开发者可以很方便的对应用程序进行二次扩展；": "通过反射机制，开发者可以实现JavaScript与原生语言(Android/Java 或 iOS/Objective-C)的相互调用，通过反射机制开发者可以很方便的对应用程序进行二次扩展；",

  // productpage_LayaAirTech
  "LayaAir引擎免费服务": "LayaAir引擎免费服务",
  "LayaAir引擎为行业提供了免费的开源引擎与IDE，以及免费的文档、API、示例、社区等基础服务。": "LayaAir引擎为行业提供了免费的开源引擎与IDE，以及免费的文档、API、示例、社区等基础服务。",
  "开源引擎:": "开源引擎:",
  "免费文档:": "免费文档:",
  "免费API:": "免费API:",
  "免费Demo:": "免费Demo:",
  "开发者社区:": "开发者社区:",
  "LayaAir引擎VIP定制服务": "LayaAir引擎VIP定制服务",
  "除了免费的基础服务外，如果开发者产生更多一对一技术服务需求。例如引擎使用答疑、BUG优先处理、引擎功能定制等、项目性能优化、一对一教学培训等需求。": "除了免费的基础服务外，如果开发者产生更多一对一技术服务需求。例如引擎使用答疑、BUG优先处理、引擎功能定制等、项目性能优化、一对一教学培训等需求。",
  "可与我们联系，针对具体需求，提供定制化的技术服务。": "可与我们联系，针对具体需求，提供定制化的技术服务。",
  "微信帐号:LayaAir_Engine": "微信帐号:LayaAir_Engine",

  // 首页
  "蓝亚宇宙": "蓝亚宇宙",
  "蓝亚宇宙是基于LayaAir引擎研发的元宇宙平台，该平台拥有众多商业级别元宇宙成功案例，例如《云上国漫城》、《南开大学数字元空间》、《国家电网廉政展厅元宇宙空间》、《工信部工业元宇宙协同发展组织元宇宙空间》等等。": "蓝亚宇宙是基于LayaAir引擎研发的元宇宙平台，该平台拥有众多商业级别元宇宙成功案例，例如《云上国漫城》、《南开大学数字元空间》、《国家电网廉政展厅元宇宙空间》、《工信部工业元宇宙协同发展组织元宇宙空间》等等。",
  "穿越火线（Cross Fire，简称CF）是经典的知名游戏IP，由腾讯采用LayaAir引擎研发，2020年移植到微信小游戏平台的CF正版FPS手游。": "穿越火线（Cross Fire，简称CF）是经典的知名游戏IP，由腾讯采用LayaAir引擎研发，2020年移植到微信小游戏平台的CF正版FPS手游。",
  "王者别嚣张是一款基于LayaAir引擎的3D MOBA竞技对决游戏。公平对战、5V5王者峡谷，精彩的对局玩法，爽快的竞技过程，极速匹配为你找寻实力相当的对手同场竞技，享受刺激精彩的冒险挑战，还原MOBA经典体验！": "王者别嚣张是一款基于LayaAir引擎的3D MOBA竞技对决游戏。公平对战、5V5王者峡谷，精彩的对局玩法，爽快的竞技过程，极速匹配为你找寻实力相当的对手同场竞技，享受刺激精彩的冒险挑战，还原MOBA经典体验！",
  "跳舞的线小游戏版，是一款基于LayaAir引擎的集快节奏操控与精选音轨于一身的节奏敏捷游戏。玩家不仅需要双眼来观察障碍物与陷阱，更需用双耳聆听节奏，旋律与节奏会帮助玩家走得更远！": "跳舞的线小游戏版，是一款基于LayaAir引擎的集快节奏操控与精选音轨于一身的节奏敏捷游戏。玩家不仅需要双眼来观察障碍物与陷阱，更需用双耳聆听节奏，旋律与节奏会帮助玩家走得更远！",
  "《第7装甲师》小游戏版，采用LayaAir引擎研发，高度还原现代战争，逼真至极！酷炫合击战斗画面，全屏燃爆！畅快改装，任意设计自己的坦克，加入PVP多人战场团战，战力飙升傲视全服！": "《第7装甲师》小游戏版，采用LayaAir引擎研发，高度还原现代战争，逼真至极！酷炫合击战斗画面，全屏燃爆！畅快改装，任意设计自己的坦克，加入PVP多人战场团战，战力飙升傲视全服！",
  "消灭病毒是由青岛蓝飞互娱采用LayaAir引擎制作的爆款产品，游戏的灵感源自一部叫《工作细胞》的动画。玩家只需要控制飞机移动并击破屏幕上方不停分裂的数字“病毒”即可。上手简单趣味无穷，火爆各平台。": "消灭病毒是由青岛蓝飞互娱采用LayaAir引擎制作的爆款产品，游戏的灵感源自一部叫《工作细胞》的动画。玩家只需要控制飞机移动并击破屏幕上方不停分裂的数字“病毒”即可。上手简单趣味无穷，火爆各平台。",
  "奇迹MU正版授权魔幻大作《大天使之剑》，三七游戏采用LayaAir引擎研发，月流水破亿人民币的爆款产品。完美传承暗黑魔幻玩法，新增野外探险，1人控团杀BOSS等玩法。": "奇迹MU正版授权魔幻大作《大天使之剑》，三七游戏采用LayaAir引擎研发，月流水破亿人民币的爆款产品。完美传承暗黑魔幻玩法，新增野外探险，1人控团杀BOSS等玩法。",
  "格斗战机是一款基于LayaAir引擎研发的二战题材空战射击游戏。该作一改传统飞机游戏的竖版躲子弹模式，让飞机在各方向都能够自由飞行，通过咬尾攻击，达到真实空战的效果，同时实现了“大筋斗”、破“S”机动，上升回旋、急转弯 等空中格斗机动动作。": "格斗战机是一款基于LayaAir引擎研发的二战题材空战射击游戏。该作一改传统飞机游戏的竖版躲子弹模式，让飞机在各方向都能够自由飞行，通过咬尾攻击，达到真实空战的效果，同时实现了“大筋斗”、破“S”机动，上升回旋、急转弯 等空中格斗机动动作。",
  "全民大乐斗是腾讯魔镜工作室采用LayaAir引擎自研的回合制游戏。Q版卡通题材，玩家代表一只胸怀武林梦想的企鹅，经历各种洗礼和挑战最终实现自己的武林梦想。": "全民大乐斗是腾讯魔镜工作室采用LayaAir引擎自研的回合制游戏。Q版卡通题材，玩家代表一只胸怀武林梦想的企鹅，经历各种洗礼和挑战最终实现自己的武林梦想。",
  "全民疯狂战机": "全民疯狂战机",
  "全民疯狂战机采用LayaAir引擎研发，是一款应用了部分3D引擎技术的2D飞行射击游戏，完美的3D效果与2D体验混合，打造出华丽弹幕、炫酷特效的视觉盛宴！": "全民疯狂战机采用LayaAir引擎研发，是一款应用了部分3D引擎技术的2D飞行射击游戏，完美的3D效果与2D体验混合，打造出华丽弹幕、炫酷特效的视觉盛宴！",
  "LayaAir 3 优势": "LayaAir 3 优势",
  "LayaAir 3 advantages": "LayaAir 3 advantages",
  "极致性能": "极致性能",
  "优异的性能一直是LayaAir引擎的核心追求，永无止境，极致追求。": "优异的性能一直是LayaAir引擎的核心追求，永无止境，极致追求。",
  "领跑Web 3D引擎": "领跑Web 3D引擎",
  "LayaAir自2016年推出3D引擎以来，多年商业案例技术积累，是基于web 图形API最成熟的国产开源3D引擎。在行业中已达成共识：开发3D小游戏就用LayaAir 。": "LayaAir自2016年推出3D引擎以来，多年商业案例技术积累，是基于web 图形API最成熟的国产开源3D引擎。在行业中已达成共识：开发3D小游戏就用LayaAir 。",
  "完善工具链+AIGC": "完善工具链+AIGC",
  "支持UI编辑器、3D场景编辑器、3D材质编辑器、3D粒子编辑器、3D蓝图编辑器、动画编辑器(2D+3D)、物理编辑器(2D+3D)、插件系统、资源商店、代码与美术设计分离等，以及AI创作生成、编辑器AI控制、引擎技术AI客服等AI插件能力！": "支持UI编辑器、3D场景编辑器、3D材质编辑器、3D粒子编辑器、3D蓝图编辑器、动画编辑器(2D+3D)、物理编辑器(2D+3D)、插件系统、资源商店、代码与美术设计分离等，以及AI创作生成、编辑器AI控制、引擎技术AI客服等AI插件能力！",
  "全平台发布": "全平台发布",
  "一次开发，可同时发布Web版、安装包（安卓与iOS）、小游戏平台。": "一次开发，可同时发布Web版、安装包（安卓与iOS）、小游戏平台。",
  "行业优选": "行业优选",
  "拥有超百万的开发者生态，腾讯、阿里巴巴、网易、三七互娱、掌趣科技、猎豹移动、汤姆猫、商汤科技、电魂网络等大型企业选用的引擎。": "拥有超百万的开发者生态，腾讯、阿里巴巴、网易、三七互娱、掌趣科技、猎豹移动、汤姆猫、商汤科技、电魂网络等大型企业选用的引擎。",
  "应用广泛": "应用广泛",
  "适用于游戏、广告营销、教育、动画、元宇宙、数字人、数字孪生、智慧城市、工业控制、仿真模拟、国防模拟、线上展示（展厅、看房、旅行……）等众多3D 数字领域。": "适用于游戏、广告营销、教育、动画、元宇宙、数字人、数字孪生、智慧城市、工业控制、仿真模拟、国防模拟、线上展示（展厅、看房、旅行……）等众多3D 数字领域。",
  "引擎开发者": "引擎开发者",
  "引擎合作伙伴": "引擎合作伙伴",

  // 头部
  "首页": "首页",
  "产品": "产品",
  "动态": "动态",
  "案例": "案例",
  "引擎社区": "引擎社区",
  "资源商店": "资源商店",
  "API": "API",
  "文档": "文档",
  "示例": "示例",
  "引擎下载": "引擎下载",
  "全文检索": "全文检索",
  "语言切换": "语言切换",

  // 底部
  "其他服务": "其他服务",
  "教学视频": "教学视频",
  "Github源码": "Github源码",
  "Gitee源码": "Gitee源码",
  "LayaAir2.0引擎": "LayaAir2.0引擎",
  "关于我们": "关于我们",
  "人才招聘": "人才招聘",
  "隐私政策": "隐私政策",
  "用户协议": "用户协议",
  "友情链接：": "友情链接：",
};
