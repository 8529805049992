export default 
{
  // enginedemo
  "引擎案例": "Engine case",
  "以下案例请使用微信扫码体验": "Please use WeChat to scan the code and experience the following case",
  "3D案例": "3D case",
  "2D案例": "2D case",
  "微信扫码体验": "Scan the QR code with WeChat to experience",
  "全民枪神边境王者": "National Gun God, Border King",
  "这是一款FPS竞技射击的重度H5游戏，完美传承了经典枪战品质和玩法，全3D视角，自由控制移动射击，真人对战体验。": "This is a hardcore H5 FPS competitive shooting game that perfectly inherits the classic gunfight quality and gameplay, with a full 3D perspective, free control of movement and shooting, and a real-life combat experience.",
  "第7装甲师": "7th Armored Division",
  "高度还原现代战争，逼真至极！酷炫合击战斗画面，全屏燃爆！四国阵营相杀相克，实时真人PVP，战力飙升傲视全服，军衔成长号令三军,坐享权力巅峰！探索战争电影里才有的史诗剧情，消灭恐怖势力，维护世界和平！": "Highly restores modern warfare, extremely realistic! Cool combined attack combat scenes, full-screen explosive action! Four-nation factions kill and counter each other, real-time live PVP, combat power soars to dominate the entire server, military rank growth commands the three armies, sitting at the peak of power! Explore epic storylines only found in war movies, eliminate terrorist forces, and maintain world peace!",
  "穿越火线-枪战王者": "CrossFire: King of Shooting",
  "穿越火线（Cross Fire，简称CF）是经典的知名游戏IP，由腾讯采用LayaAir 3D引擎研发，2020年移植到微信小游戏平台的CF正版FPS手游。": "Cross Fire (abbreviated as CF) is a classic and well-known game IP, developed by Tencent using the LayaAir 3D engine, and in 2020, it was ported to the WeChat Mini Games platform as an official CF FPS mobile game.",
  "王者别嚣张": "King, don't be arrogant",
  "这是一款全新的3D MOBA竞技对决手游。公平5V5竞技手游，精彩的对局玩法，爽快的竞技过程，极速匹配为你找寻实力相当的对手同场竞技，享受刺激精彩的冒险挑战，在一场场的对局中收获属于你的胜利荣耀！": "This is a brand new 3D MOBA competitive mobile game. A fair 5V5 competitive mobile game, with exciting gameplay, exhilarating competitive process, and rapid matching to find opponents of equal strength for you to compete with. Enjoy thrilling and exciting adventure challenges, and reap your victory and glory in each match!",
  "300大作战": "300 Heroes Battle",
  "这是一款二次元3D MOBA游戏，5v5公平竞技！皮肤无属性，战斗无符文，实时跨区匹配，意想不到的作战模式，指尖爽快操作，极致战斗体验！匠心绘制精美立绘，可爱小姐姐陪你上分，全新娱乐模式，随机道具，惊喜连连，更有7V7神器战场，极限养成，酣畅淋漓！": "This is a 2D element 3D MOBA game, 5v5 fair competition! Skins have no attributes, battles have no runes, real-time cross-region matchmaking, unexpected combat modes, smooth fingertip control, and the ultimate combat experience! Exquisitely crafted beautiful character portraits, cute little sisters to help you rank up, a brand-new entertainment mode, random items, continuous surprises, and even a 7V7 artifact battlefield, extreme development, exhilarating to the fullest!",
  "我的大刀四十米": "My broadsword is forty meters long",
  "我的大刀四十米是一款热门榜单上的爆款竞技游戏，玩家将在这里上演激情刀战，只有以一人之力击败所有人才能称之为真正的强者。": "My Great Sword Forty Meters is a popular chart-topping competitive game where players will stage passionate knife fights, and only by defeating everyone single-handedly can one be called a true powerhouse.",
  "拇指射箭": "Thumb archery",
  "一款射箭类休闲竞技游戏。玩家将扮演一位初级射箭运动爱好者，不断挑战更高难度的箭靶来提升自己的射箭技术，慢慢变成一位技艺精湛的射箭大师。": "An archery-themed casual competitive game. Players will take on the role of a beginner archery enthusiast, constantly challenging more difficult targets to improve their archery skills, gradually becoming a master archer with exquisite technique.",
  "跳舞的线": "Dancing Line",
  "这是一款魔性又虐手的音乐跑酷游戏，玩家不仅需要用双眼来观察障碍物与陷阱，更需要用双耳聆听节奏，旋律与节奏会帮助玩家走得更远。": "This is a bewitching and hand-torturing music parkour game, where players need to use their eyes to observe obstacles and traps, and their ears to listen to the rhythm. The melody and rhythm will help players go further.",
  "画将军": "General Painting",
  "画将军是一款热血的竞技策略战争游戏，玩家动动画笔，就可以操控战场，不断挑战新的关卡。": "Drawing Generals is a passionate competitive strategy war game where players can control the battlefield with a stroke of the pen, constantly challenging new levels.",
  "街头扣篮王": "Street Slam Dunk King",
  "街头扣篮王是热门的微信创意小游戏，Q版风格打造的篮球休闲游戏,，3v3的篮球对抗赛，暴爽的扣篮、过人、抢断、投篮等游戏体验。": "Street Slam Dunk King is a popular WeChat creative mini-game, a Q-style basketball casual game, a 3v3 basketball match, with thrilling dunking, dribbling, stealing, shooting, and other gaming experiences.",
  "Parkour Race": "Parkour Race",
  "跑酷竞赛是由Madbox工作室开发的3D跑酷游戏。发布于全球知名的H5游戏网站poki，受到到热门推荐。（tips:有上架poki平台的开发者可与Layabox商务联系）": "Parkour Race is a 3D parkour game developed by Madbox Studios. It was released on the globally renowned H5 game website Poki and received a hot recommendation. (Tips: Developers who have launched on the Poki platform can contact Layabox Business.)",
  "天空跑酷3D": "Sky Parkour 3D",
  "是模拟极限运动高空落差跳玩法的跑酷游戏，多个橡皮人同场竞技，在体验惊险刺激的跑酷游戏同时，去赢得第一名。": "It is a parkour game that simulates extreme sports high-altitude drop jumping. Multiple rubber men compete in the same arena, experiencing the thrilling parkour game while trying to win first place.",
  "滑板冲冲冲": "Skateboarding, go go go!",
  "是在U池赛道中滑板跑酷的休闲竞速游戏，该游戏的动作细节真实模拟了现实中滑板跑酷的原型，可玩性超赞。": "It is a casual racing game of skateboarding parkour on the U-pool track, with action details that realistically simulate the prototype of real-life skateboarding parkour, offering superb playability.",
  "滚动的天空": "Rolling Sky",
  "是非常考验反应力的休闲跑酷游戏，游戏中不仅关卡场景丰富华丽，每个场景中的元素也非常丰富。": "It is a casual parkour game that greatly tests your reflexes. Not only are the levels rich and gorgeous, but each scene is also very abundant with elements.",
  "指尖乐动": "Fingertip Rhythm",
  "是标杆级的小球跳跃3D游戏，时尚潮流的音乐配上精美的游戏场景，动感的节奏，欢快的跳跃，可玩性超赞。": "It is a benchmark-level ball jumping 3D game, with fashionable music paired with exquisite game scenes, dynamic rhythm, and cheerful jumping, the playability is awesome.",
  "迷你跑酷": "Mini Parkour",
  "是3D立方体的格子滚动跑酷游戏，左右手各控制一个方向，每次点击前要做好预判，脑中规划好行走路径。": "It is a 3D cube grid scrolling parkour game, with the left and right hands controlling one direction each. You must make a prediction before each click and plan the walking path in your mind.",
  "水上乐园滑梯竞速": "Water Park Slide Racing",
  "是模拟了现实中水上乐园的滑梯玩法，逼真的体验，丰富的皮肤、还有道具合成玩法、无尽模式玩法等。": "It simulates the slide gameplay of water parks in real life, with realistic experiences, a variety of skins, as well as prop synthesis gameplay, endless mode gameplay, and more.",
  "沙滩大作战": "Beach Battle Royale",
  "这是一款以抢占高地的炮台射击得分的休闲对战小游戏。玩家控制的角色先要躲避炮台上敌人发射的滚石炮弹。冲上炮台后射杀其它要占领炮台的敌人可获得积分。": "This is a casual competitive mini-game where scoring is based on capturing the high ground and shooting from cannons. Players control characters that must first dodge rolling cannonballs fired by enemies on the cannons. After rushing to the cannons, shooting other enemies trying to capture the cannons will earn points.",
  "王牌机师3D": "Ace Pilot 3D",
  "这是一款3D机甲飞机空战游戏 ，玩家通过火控雷达来锁定敌机，并同时发射导弹击溃敌机来完成闯关条件。面对敌方的导弹可以通过左右侧飞或者翻转来躲避。": "This is a 3D mecha aircraft air combat game, where players use fire control radar to lock onto enemy aircraft and launch missiles to destroy them in order to complete the level. To avoid enemy missiles, players can dodge by flying to the left or right or by performing a roll.",
  "一炮到底3D": "From Start to Finish 3D",
  "这是一款物理撞击的3D射击游戏。玩家点哪里，大炮就射向哪里，射出的炮弹与各种形状的物体发生碰撞，将其撞碎或撞出板面，完成闯关目标。": "This is a 3D shooting game with physical collisions. Wherever the player points, the cannon shoots there, and the cannonballs collide with objects of various shapes, smashing them or knocking them off the board to complete the level objectives.",
  "腾讯桌球": "Tencent Billiards",
  "是腾讯光子工作室研发的一款基于真实玩家在线匹配的竞技对战游戏，运用了LayaAir引擎的3D技术，真实还原了桌球的质感与运动中的球感，一经推出，深受广大玩家的喜爱。": "It is a competitive battle game developed by Tencent Photonics Studio based on real player online matching, utilizing the 3D technology of the LayaAir engine, which authentically restores the texture of billiards and the sensation of the ball in motion. Upon its release, it was well-received by a vast number of players.",
  "荒野大淘金": "Gold Rush in the Wilderness",
  "多人同屏竞技的3D捕鱼玩法，荒野丛林的主题，畅快的野兽暴击，绚丽的特效，与众不同的体验，值得一试。": "Multiplayer competitive 3D fishing gameplay, wilderness jungle theme, exhilarating wild beast critical hits, dazzling special effects, a unique experience worth trying.",
  "楼梯大作战": "Staircase Battle",
  "《楼梯大作战》是一款3D的微信休闲益智小游戏，以楼梯为背景，用户通过手指简单的左右移动控制球的跳动方向来躲避台阶上的障碍物，以及拾取钻石，超强的3D视角，让游戏更加的刺激。": "\"Staircase Battle\" is a 3D WeChat casual puzzle mini-game that uses stairs as the backdrop. Players control the direction of the bouncing ball by simply moving their fingers left and right to avoid obstacles on the steps and to collect diamonds. The strong 3D perspective makes the game even more exciting.",
  "车轮压一压": "The wheel presses down",
  "是一款减压的物理运动小游戏，让巨大的车轮碾压一切障碍物体吧。": "It is a stress-relieving physical activity mini-game, let the huge wheel crush all obstacles.",
  "叠高跑圈圈": "Stack high, run in circles",
  "是一款轻松有趣的叠方块跑酷小游戏，叠的越高，越容易安全过关。": "It is an easy and fun stacking block parkour game; the higher you stack, the easier it is to pass the level safely.",
  "给脚就跑": "Start running as soon as you have feet",
  "是一款画线跑酷小游戏，画的线是什么形状，腿就张什么样，合理的形状更加方便你通过关卡障碍。": "It is a line-drawing parkour mini-game, the shape of the line you draw determines the position of the legs, and a reasonable shape makes it easier for you to pass through the level obstacles.",
  "机械球快跑": "The mechanical ball runs fast",
  "全球风靡的足球游戏，点球大战，等你来挑战。": "The globally popular football game, penalty shootout, is waiting for you to challenge.",
  "大天使之剑H5": "Archangel Sword H5",
  "全新交互玩法，再现经典奇迹MU魔幻潮流，全新动态野外BOSS概念，野外探险、击杀BOSS、打怪掉宝，现在是时候来一场痛快野外厮杀了！": "Brand new interactive gameplay, recreating the classic miracle MU magical trend, a new dynamic outdoor BOSS concept, outdoor exploration, killing BOSS, fighting monsters for loot, now is the time for an exhilarating outdoor battle!",
  "消灭病毒": "Eliminate the virus",
  "一款火爆各平台的休闲躲避射击游戏，玩家可以使用不同的武器、升级这些武器和日常收益，来消灭各种病毒。": "A popular casual dodge shooting game across various platforms, where players can use different weapons, upgrade these weapons and daily earnings to eliminate all kinds of viruses.",
  "格斗战机": "Fighting Fighter Jet",
  "一款有着二战写实风格的飞机格斗空战游戏，战机真实丰富，战场逼真还原二战的战场风格，强烈推荐体验。": "A realistic World War II style aircraft combat game with authentic and diverse warplanes, the battlefield realistically restores the style of World War II battlefields, highly recommended for an experience.",
  "天天打怪兽": "Every day fighting monsters",
  "一款飞行射击游戏，玩家通过操控自己的战机躲避怪兽的子弹攻击，自己飞机发射子弹攻击怪兽。": "A flight shooting game where players control their own fighter jets to dodge the bullets of monsters and shoot bullets to attack the monsters.",
  "三国一统天下": "The Three Kingdoms unify the realm",
  "一款以三国历史为背景的策略性游戏，带你进入正统的三国世界！排兵布阵、历史战役、挥兵战场。": "A strategy game set against the backdrop of the Three Kingdoms history, taking you into the authentic world of the Three Kingdoms! Deploy troops, historical battles, command armies on the battlefield.",
  "欢乐小飞机Q版": "Joyful Little Plane Q Version",
  "经典的飞行射击游戏，像素画风，回忆杀雷电、1945、武装飞鸟、战国之刃！": "Classic flying shooting game, pixel art style, reminiscent of Raiden, 1945, Armed Birds, Blade of the Samurai!",
  "全民大乐斗": "National Carnival Battle",
  "《全民大乐斗》是腾讯魔镜工作室自研的回合制游戏。": "\"Full People's Great Battle\" is a turn-based game independently developed by Tencent Magic Mirror Studio.",
  "怼怼三国": "Three Kingdoms Confrontation",
  "一款类皇室战争的横版H5策略竞技游戏，玩家将通过竞技战斗收集武将、小队及法术卡牌，搭配合适自己策略与其他玩家进行战斗。": "A horizontal H5 strategy competitive game similar to Clash Royale, where players collect generals, squads, and spell cards through competitive battles, and match them with their own strategies to fight against other players.",
  "切水果王者版": "Fruit Cutting King Version",
  "开局一把刀，粉碎所有水果！这是一款休闲的切水果游戏，通过飞镖等武器命中全部的水果来获得爆浆的快感！": "Start with a knife, crush all the fruits! This is a casual fruit slicing game, where you hit all the fruits with weapons like darts to experience the thrill of bursting them!",

  // engineDownload
  "我接受条款": "I accept the terms",
  "我确认我或我的公司愿意遵守LayaAir引擎的以下使用条件，否则将下架线上产品直到停止引擎的使用，条款如下：": "I confirm that I or my company is willing to comply with the following terms of use for the LayaAir engine, otherwise the online products will be removed until the use of the engine is discontinued, the terms are as follows:",
  "Layabox引擎软件使用条款全文": "Layabox Engine Software Terms of Use Full Text",
  "在游戏加载页或首页的任意可见位置注明：Powered by LayaAir Engine": "Indicate in any visible location on the game loading page or homepage: Powered by LayaAir Engine",
  "如果想去除引擎相关标识，请联系Layabox商务合作对接人进行授权。": "If you want to remove the engine-related marks, please contact the Layabox business cooperation liaison for authorization.",
  "立即下载": "Download",
  "更新时间：": "Update Time:",

  // engineIntroduction
  "引擎简介": "Engine Introduction",
  "招聘信息": "Job Vacancy Information",
  "联系方式": "Our Company",
  "LayaAir是Layabox旗下HTML5开源引擎，发布于2016年，拥有成熟的引擎架构与配套工具链，一次开发，全平台（HTML5小游戏Native APP）发布。广泛应用于2D与3D游戏、应用软件、广告与营销、教育、智慧城市、元宇宙等众多领域的开发。已拥有开发者超100万，服务于腾讯、阿里巴巴、网易、三七互娱、完美世界、电魂网络、光宇游戏、汤姆猫、猎豹移动、第七大道、掌趣、商汤科技、精锐教育等众多使用LayaAir的知名企业，在3D小游戏领域的引擎市场始终保持行业龙头地位。": "LayaAir is an open-source HTML5 engine under Layabox, released in 2016. It has a mature engine architecture and a complete set of toolchains, allowing for one-time development and multi-platform (HTML5, mini-games, Native APP) release. It is widely used in the development of various fields such as 2D and 3D games, application software, advertising and marketing, education, smart cities, metaverse, etc. It already has over one million developers and serves many well-known enterprises that use LayaAir, including Tencent, Alibaba, NetEase, 37 Interactive Entertainment, Perfect World, Electric Soul Network, Guangyu Games, Talking Tom, Cheetah Mobile, Seventh Avenue, SenseTime, Elite Education, and others. It maintains a leading position in the engine market for 3D web-games.",
  "2023年10月，LayaAir引擎团队与掌趣科技合作，发布LayaAir 3.1版本——正式成为一款具备AI生产力的游戏研发引擎，全面拥抱AIGC（人工智能生成内容）。引擎支持AI创作生成、编辑器的AI控制、引擎技术AI客服等AI插件能力。未来，LayaAir将在保障在国产Web 3D引擎渲染能力持续领先的基础上，继续深入与掌趣科技的合作，大力发展AIGC与3D引擎的结合，定位“最易用的AIGC引擎生态”。": "In October 2023, the LayaAir engine team collaborated with Ourpalm Co., Ltd to release LayaAir 3.1 version—officially becoming a game development engine with AI productivity, fully embracing AIGC (Artificial Intelligence Generated Content). The engine supports AI-powered content creation, AI control in the editor, and AI customer service for engine technology, among other AI plugin capabilities. In the future, LayaAir will continue to deepen its cooperation with Ourpalm Co., Ltd on the basis of ensuring its leading position in domestic Web 3D engine rendering capabilities, vigorously developing the integration of AIGC with the 3D engine, and positioning itself as \"the most user-friendly AIGC engine ecosystem.\"",
  "招贤纳士": "Recruit talented people",
  "JOB POSITION": "JOB POSITION",
  "Layabox是HTML5引擎技术提供商与技术服务商。欢迎各种人才的加入，这里给您最大的发挥空间，一起共创辉煌": "Layabox is an HTML5 engine technology provider and technical service provider. We welcome the addition of various talents, offering you the greatest space to excel and create brilliance together.",
  "请投简历到HR邮箱: hr@layabox.com 期待优秀的你，共同将梦想写进未来！邮件投递请主题标注官网投递": "Please send your resume to the HR email: hr@layabox.com. We look forward to the outstanding you to join us in writing dreams into the future! Please mark the subject of the email as \"Official Website Submission\".",
  "引擎开发工程师": "Engine Development Engineer",
  "岗位职责：": "Job Responsibilities:",
  "1、负责LayaNative开发及维护工作；": "1. Responsible for LayaNative development and maintenance work;",
  "2、开发跨平台自动化发布流程；": "2. Develop cross-platform automated release processes;",
  "3、负责引擎打包底层开发，C++方向。": "3. Responsible for engine packaging and underlying development, C++ direction.",
  "任职要求：": "Job Requirements:",
  "1、良好的数学和算法基础，热爱程序工作；": "1. Good foundation in mathematics and algorithms, passionate about programming work;",
  "2、优秀的C++设计、分析能力及规范的编程风格；": "2. Excellent C++ design and analysis capabilities and standardized programming style;",
  "3、熟悉OpenGL及相关图形接口，优秀的学习研究能力；": "3. Familiar with OpenGL and related graphics interfaces, excellent learning and research abilities;",
  "4、熟悉软件开发流程，了解软件打包及更新机制；": "4. Familiar with the software development process, understand the software packaging and update mechanisms;",
  "5、擅长自动化开发工具,熟悉多平台（WIN/MAC）开发优先；": "5. Proficient in automated development tools, familiarity with multi-platform (WIN/MAC) development is preferred",
  "6、具备自我管理和驱动能力，能够独立设定工作的优先次序。": "6. Possess self-management and motivation skills, able to independently set work priorities.",
  "城市：北京": "City: Beijing",
  "3D渲染软件工程师": "3D Rendering Software Engineer",
  "1、负责网页端，移动端的3D渲染软件研究、开发与维护；": "1. Responsible for the research, development, and maintenance of 3D rendering software for web and mobile platforms",
  "2、配合策划、美术、项目等各部门工作，保证研发工作正常进行。": "2. Collaborate with the planning, art, and project departments to ensure the normal progress of research and development work.",
  "1、熟悉OpenGL, WebGL, glsl，擅长网页端，移动端的3D开发；": "1. Familiar with OpenGL, WebGL, glsl, proficient in web and mobile 3D development;",
  "2、熟悉JavaScript/TypeScript语言，良好的OOP编程思想；": "2. Familiar with JavaScript/TypeScript language, good OOP programming concept;",
  "3、热爱研发性质工作，对软件的优化和性能优化有一定研究；": "3. Passionate about research and development work, with some research on software optimization and performance enhancement",
  "4、具有二年以上3D游戏类软件的开发经验；": "4. Have more than two years of experience in developing 3D game software;",
  "5、有团队合作精神和快速执行力，能充分理解设计意图并尽可能用最优方案完成；": "5. Possess a team spirit and quick execution ability, able to fully understand the design intent and complete it with the best possible solution",
  "6、热衷于技术钻研，工作踏实诚恳，认真负责，具有主动性，有良好的抗压能力。": "6. Passionate about technical research, diligent and sincere in work, conscientious and responsible, proactive, and possessing good stress resistance.",
  "前端开发工程师": "Front-end Developer Engineer",
  "1、HTML5游戏前端开发和维护工作；": "1. HTML5 game front-end development and maintenance work;",
  "2、与策划、美术、后端以及运营人员讨论开发需求及技术实现细节；": "2. Discuss development requirements and technical implementation details with planners, artists, backend staff, and operations personnel",
  "3、完成相关的技术文档编写。": "3. Complete the writing of relevant technical documentation.",
  "1、熟悉主流H5游戏引擎，使用开发过一款以上游戏；": "1. Familiar with mainstream H5 game engines, having developed more than one game using them",
  "2、熟悉JS、TS、AS3语言，良好的OOP编程思想；": "2. Familiar with JS, TS, AS3 languages, good OOP programming concepts;",
  "3、熟悉 WebSocket 和 HTTP 等网络协议，熟悉JSON、ProtoBuf 等数据协议；": "3. Familiar with network protocols such as WebSocket and HTTP, familiar with data protocols such as JSON, ProtoBuf, etc.;",
  "3、有良好的代码书写、注释和单元测试习惯，熟练运用多种软件设计模式；": "3. Possess good coding, commenting, and unit testing habits, proficient in using a variety of software design patterns",
  "4、具备良好的沟通合作技巧，较强的责任心及团队合作精神。": "4. Possess good communication and cooperation skills, a strong sense of responsibility, and team spirit.",
  "城市：北京/太原": "City: Beijing/Taiyuan",
  "JAVA后端开发工程师": "Java Backend Development Engineer",
  "1、参与帧同步、时间同步服务器端引擎的开发；": "1. Participate in the development of frame synchronization and time synchronization server-side engines",
  "2、服务端业务模块的开发；": "2. Development of server-side business modules;",
  "3、根据产品需求进行程序设计，并编写详细设计文档。": "3. Design the program according to product requirements and write detailed design documents.",
  "职位要求：": "Position Requirements:",
  "1、5年以上相关开发经验；": "1. Over 5 years of relevant development experience;",
  "2、精通 JAVA、精通 springboot、springcloud 框架；": "2. Proficient in JAVA, proficient in Spring Boot, Spring Cloud frameworks",
  "4、精通网络通讯原理；": "4. Proficient in the principles of network communication;",
  "5、精通多线程的程序设计、开发；": "5. Proficient in multi-threaded program design and development;",
  "6、熟练掌握常用的设计模式；": "6. Proficient in common design patterns;",
  "7、熟悉Mysql数据库、redis缓存、消息队列、Elasticsearch的开发使用；": "7. Familiar with the development and use of MySQL database, Redis cache, message queue, and Elasticsearch",
  "8、为人踏实，拥有严谨的工作态度，善于学习新知识；": "8. Down-to-earth, possessing a meticulous work attitude, and good at learning new knowledge;",
  "7、良好的团队合作精神，善于沟通，有高度责任感，能够承受较大的工作压力；": "7. Good team spirit, good at communication, highly responsible, and able to withstand considerable work pressure;",
  "Web服务器开发工程师": "Web Server Development Engineer",
  "1、负责后台业务模块开发，后台管理相关页面的开发；": "1. Responsible for the development of backend business modules and the development of related pages for backend management",
  "2、与产品经理沟通产品需求，把握需求的合理性和优先级；": "2. Communicate with the product manager about product requirements, and grasp the rationality and priority of the requirements",
  "1. 3年以上相关开发经验；": "1. More than 3 years of relevant development experience;",
  "2、精通 PHP，至少精通一个PHP开发框架；": "2. Proficient in PHP, at least proficient in one PHP development framework;",
  "3、精通 MySQL 的开发、调优；": "3. Proficient in MySQL development and optimization;",
  "4、熟悉高并发网站开发，分布式系统开发，能对分布式技术进行合理应用；": "4. Familiar with the development of high-concurrency websites and distributed systems, capable of applying distributed technology appropriately",
  "5、熟悉 Elasticsearch、消息队列、MongoDb、Redis；": "5. Familiar with Elasticsearch, message queue, MongoDB, Redis;",
  "6、熟悉掌握HTML5，CSS，Javascript，JQuery，AJAX，JSON , VUE等web页面技术；": "6. Familiar with mastering HTML5, CSS, Javascript, JQuery, AJAX, JSON, VUE, and other web page technologies;",
  "7、为人踏实，拥有严谨的工作态度，善于学习新知识；": "7. Down-to-earth, possessing a meticulous work attitude, and good at learning new knowledge",
  "8、良好的团队合作精神，善于沟通，有高度责任感，能够承受较大的工作压力。": "8. Good team spirit, good at communication, highly responsible, and able to withstand considerable work pressure.",
  "项目经理": "Project Manager",
  "1. 需求分析：与外部与内部的相关方进行沟通、需求分析、合理性评估；": "1. Demand analysis: Communicate with external and internal stakeholders, conduct demand analysis, and assess feasibility.",
  "2. 项目排期：基于合作方需求、内部资源协调进行项目排期；": "2. Project scheduling: Schedule projects based on partner needs and internal resource coordination;",
  "3. 风险把控：基于内部与外部的变化，项目进度的梳理，及时沟通协调，调整方案化解问题；": "3. Risk control: Based on internal and external changes, comb through the project progress, communicate and coordinate in a timely manner, adjust plans to resolve issues.",
  "4. 质量把控：把控产品需求的颗粒度、美术资源的质量、技术交付质量、完整体验项目流程，对项目质量负责；": "4. Quality Control: Control the granularity of product requirements, the quality of art resources, the quality of technical delivery, and the entire project experience process, responsible for project quality.",
  "5. 过程管理、最终交付：把控项目不同环节、不同部门之间的任务并行串行时的衔接问题，提醒和督促相关环节，最终给出符合质量要求的项目交付。": "5. Process management, final delivery: Control the connection issues of tasks being carried out in parallel or in series among different stages and departments of the project, remind and urge the relevant stages, and ultimately deliver the project that meets the quality requirements.",
  "1. 追求极致效率，快速推进业务；发现团队的模糊地带能及时推动制定相关的业务标准流程；": "1. Pursue ultimate efficiency, rapidly advance business; identify the team's areas of ambiguity and promptly promote the establishment of relevant business standard procedures.",
  "2. 在调研分析、任务拆解上有多许多经验与积累；": "2. Has a lot of experience and accumulation in research analysis and task breakdown;",
  "3. 拥有良好的沟通协调能力，处理协调过与合作方、内部之间的分歧与矛盾；": "3. Possess good communication and coordination skills, and have handled and coordinated differences and contradictions between partners and internally.",
  "4. 在项目管理领域拥有3-5年经验，参与过项目管理的全流程，从0到1完成项目交付；": "4. Have 3-5 years of experience in the field of project management, participated in the entire process of project management, and completed project delivery from 0 to 1;",
  "5. 本科及以上学历，拥有PMP证书，项目管理相关证书的优先；": "5. Bachelor's degree or above, possession of PMP certification, priority given to those with project management related certifications;",
  "Contact Information": "Contact Information",
  "地址：北京市大兴区盛坊路元宇宙大厦9层": "Address: Room 901-3, Metaverse Building, Shengfang Road, Daxing District, Beijing",
  "邮箱：weiss@layabox.com、bd@layabox.com": "Email: weiss@layabox.com、bd@layabox.com",
  "电话：18611806735、010-56277796": "Telephone: 18611806735、010-56277796",
  "公众号二维码": "Public account QR code",
  "城市：北京/成都/太原": "City: Beijing/Chengdu/Taiyuan",
  "城市：成都/北京": "City: Chengdu/Beijing",
  "Layaverse美术总监": "Layaverse Art Director",
  "1、了解策划意图，依据场景设计流程跟进后续流程，参与游戏最终效果审核；": "1. Understand the planning intent, design the process according to the scene, and participate in the final effect review of the game;",
  "2、与项目组美术、程序与策划人员深入沟通，准确实现项目开发需求；": "2. Deeply communicate with the art, program and planning personnel of the project group, accurately implement the project development requirements;",
  "3、负责项目中场景规划及原画设计；": "3. Responsible for the scene planning and original art design in the project;",
  "4、负责场景概念气氛设计和场景配图；": "4. Responsible for the design of scene concept atmosphere and scene background;",
  "5、负责创意宣传画绘制。": "5. Responsible for the drawing of creative publicity art.",
  "1、5年以上游戏原画工作经验，美术专业优先，美术基本功扎实。专业能力突出，审美能力强；": "1. More than 5 years of experience in game original art, preferring art majors. Professional ability outstanding, strong sense of aesthetics;",
  "2、学习能力强；做事认真；有合作意识；容易接受新事物，有创新精神；": "2. Learning ability is strong; serious in work; have a cooperative consciousness; easy to accept new things, have a creative spirit;",
  "3、有建筑设计，写实、科幻类、沙盒类项目经验者优先；": "3. Prior experience in architectural design, realistic, sci-fi, sandbox projects is preferred;",
  "4、精通建模、设计等相关软件者优先。": "4. Preferred to be proficient in modeling, design and other related software.",
  "城市：北京/成都": "City: Beijing/Chengdu",
  "创意概念设计师/资深原画设计师": "Conceptual designer/Senior artist",
  "1、负责项目世界观搭建、概念设计、地图建筑规划，功能区划分；": "1. Responsible for the construction of the project world view, conceptual design, map building planning, functional area division;",
  "2、了解产品意图，能够独立完成高品质场景气氛图和场景配图，并跟进后续流程参与实现效果落地；": "2. Understand the product intent and be able to independently complete high-quality scene atmosphere maps and scene background maps, and participate in the subsequent process of implementation effects;",
  "3、能够通过与需求方的交流，理解意图，提出独到的设计方案。": "3. Be able to understand the intention through communication with the demand side and come up with unique design solutions.",
  "1、美术专业科班出身、拥有国际化视野、三年以上原画设计工作经验；": "1. Graduated from an art major with international vision and more than three years of experience in original art design;",
  "2、较强的绘画功底、深刻了解主界面，城市的规划等优先次序；": "2. Strong drawing foundation, deep understanding of the main interface, city planning and other priority order;",
  "3、热爱新事物、喜欢创新与探索、视野宽泛、对高品质游戏拥有敏锐观察力、设计新颖，生活化并且趣味性；": "3. Love new things, love innovation and exploration, have a wide vision, have a keen eye for high-quality games, design innovative and explore, live a life that is interesting and fun;",
  "4、善于沟通、积极主动、有很好的团队意识和换位思考能力；": "4. Good at communication, proactive, have a good team spirit and ability to think in terms of others;",
  "5、有过科幻游戏、沙盘规划制作经验者优先。": "5. Prior experience in creating science fiction games, sand table planning and production is preferred.",
  "商务合作微信": "WeChat for Business Cooperation",
  "地址：北京市海淀区中关村壹号C2座": "Address: C2 Building, No. 1, Zhongguancun E-commerce District, Beijing",
  "城市：成都": "City: Chengdu",

  // 引擎动态
  "引擎动态": "Engine News",
  "上一页": "prev",
  "下一页": "next",

  // productpage_LayaAirEngine
  "LayaAir 引擎": "LayaAir Engine",
  "LayaAir IDE": "LayaAir IDE",
  "LayaAir Native": "LayaAir Native",
  "LayaAir 技术服务": "LayaAir Technical Service",
  "引擎发展简介": "Introduction to Engine Development",
  "Layabox是北京蓝亚盒子科技有限公司打造的引擎服务商与元宇宙服务商企业品牌。": "Layabox is an engine service provider and metaverse service provider corporate brand created by Beijing LayaBox Technology Co., Ltd.",
  "LayaAir引擎是Layabox旗下的全平台3D引擎产品。": "The LayaAir engine is a full-platform 3D engine product under Layabox.",
  "LayaAir引擎1.0正式版，首次发公开发布于2016年6月30日，包括2D与3D引擎功能，自带UI编辑器，支持一次开发全平台发布。": "The official version 1.0 of the LayaAir engine was first publicly released on June 30, 2016, including both 2D and 3D engine capabilities, with a built-in UI editor, supporting development for all platforms with a single codebase.",
  "2016年底，基于LayaAir引擎的首款商业3D ARPG游戏《无尽骑士》上线，这也是基于webGL图形API的全球首款3D ARPG商业级大作。同时也标志着LayaAir引擎已经达到商业级3D游戏研发的成熟度。": "At the end of 2016, the first commercial 3D ARPG game based on the LayaAir engine, \"Endless Knight,\" was launched, which is also the world's first commercial-grade 3D ARPG masterpiece based on the WebGL graphics API. This also signifies that the LayaAir engine has reached the maturity level for commercial-grade 3D game development.",
  "2017年，大量基于LayaAir2D与3D的大型游戏代表作出现。": "In 2017, a large number of major games based on LayaAir2D and 3D emerged.",
  "在2D引擎案例方面：8月3日，腾讯旗下魔镜工作室采用LayaAir引擎自研的格斗类HTML5游戏《全民大乐斗》在微信、手机QQ两大平台的安卓版本公测上线。该游戏标志着LayaAir引擎的成熟度已得到顶级游戏团队的认可，并通过产品验证了引擎的成熟度。": "In terms of 2D engine cases: On August 3rd, Tencent's Magic Mirror Studio launched the public beta of the fighting-style HTML5 game \"Everybody's Happy Brawl\" on the Android versions of the WeChat and Mobile QQ platforms, using the LayaAir engine developed in-house. This game signifies that the maturity of the LayaAir engine has been recognized by top game teams and has been validated by the product.",
  "10月22日，上线24天的《大天使之剑H5》宣告流水破亿，该游戏由上市公司三七互娱旗下团队采用LayaAir引擎研发，web端链接、安卓端APP、iOS端APP同时发布。2018年，《大天使之剑H5》又发布了微信小游戏版本。该游戏代表着LayaAir引擎的高性能与成熟，足够支撑亿级流水的2D商业大作。": "On October 22nd, \"Archangel Sword H5,\" which had been online for 24 days, announced that its revenue had broken through one hundred million. The game was developed by a team under the listed company 37 Interactive Entertainment using the LayaAir engine, and was released simultaneously on web link, Android app, and iOS app. In 2018, \"Archangel Sword H5\" also launched a WeChat mini-game version. This game represents the high performance and maturity of the LayaAir engine, which is sufficient to support a 2D commercial blockbuster with a revenue of over one hundred million.",
  "在LayaAir引擎 3D案例方面，2017年7月21日，采用知名小说IP《雪鹰领主》3D ARPG游戏在腾讯玩吧首发，精细的原画、华丽的特效、结合爽快战斗打击感，流畅的原生级游戏性能表现，让LayaAir 3D引擎再一次进入大众视野。": "In the aspect of LayaAir engine 3D cases, on July 21, 2017, a 3D ARPG game using the well-known novel IP \"Snow Eagle Lord\" was launched on Tencent's gaming platform. With its fine original artwork, gorgeous effects, combined with exhilarating combat experience, and smooth native-level game performance, the LayaAir 3D engine once again came into public view.",
  "2018年，是小游戏爆发的元年，作为国内唯一拥有商业案例的Web3D游戏引擎。得到了广大开发者用户的追捧，用户基数直线飙升，从2017年的接近10万，到2018年底，已空破60万。": "2018 was the breakout year for mini-games, as the only Web3D game engine in the country with commercial cases. It was sought after by a vast number of developer users, and the user base soared, from nearly 100,000 in 2017 to over 600,000 by the end of 2018.",
  "在引擎案例与代表作方面，2018年也呈现井喷式发展，仅可统计的LayaAir 3D引擎小游戏案例已经超过300款，例如《猪猪侠快跑》、《跳舞的线》、《全民足球》、《腾讯桌球》等等知名爆款。在2018年小游戏引擎市场中，国内引擎市场占比100%，国际引擎市场占比超过97%。": "In terms of engine cases and representative works, 2018 also saw an explosive development, with over 300 LayaAir 3D engine mini-game cases that can be counted, such as \"Pig Man Run\", \"Dancing Line\", \"Everybody's Soccer\", \"Tencent Billiards\", and other well-known hit titles. In the 2018 mini-game engine market, domestic engines accounted for 100% of the market share, while international engines accounted for more than 97%.",
  "2018年07月05日，LayaAir 2.0开启内测，9月15日，LayaAir 2.0开启公测。基于LayaAir 引擎在3D领域的优异市场表现，以及用户的需求。LayaAir 2.0引擎决定全力投入3D引擎的研发，开启了Web3D国产引擎领跑产业的技术之路。": "On July 5, 2018, LayaAir 2.0 began its closed beta, and on September 15, LayaAir 2.0 started its open beta. Based on the excellent market performance of the LayaAir engine in the 3D field, as well as user demand, the LayaAir 2.0 engine decided to fully commit to the development of the 3D engine, embarking on the technical journey of leading the industry with a domestically produced Web3D engine.",
  "2019年至2022年，LayaAir 2.0始终坚定3D引擎上的极大投入，也持续占领着web3D引擎的市场龙头位置，在技术上保持着领先的优势。其间，也产生了大量2D与3D的爆款产品，例如，休闲消除游戏爆款《消灭病毒》、FPS游戏爆款《全民枪神边境王者》与《穿越火线-枪战王者》、MOBA游戏爆款《300大作战》、IO竞技游戏爆款《我的大刀四十米》、机甲PVP爆款《第7装甲师》等等。": "From 2019 to 2022, LayaAir 2.0 has always been firmly committed to a significant investment in the 3D engine and has continued to occupy the leading position in the web3D engine market, maintaining a leading technological advantage. During this period, a large number of popular 2D and 3D products were also produced, such as the popular casual elimination game \"Eliminate the Virus,\" the FPS game hits \"National Gun God: Frontier King\" and \"CrossFire: Gunfight King,\" the MOBA game hit \"300 Heroes,\" the IO competitive game hit \"My Sword is Forty Meters Long,\" the mecha PVP hit \"7th Armored Division,\" and so on.",
  "2023年6月30日，LayaAir3.0正式版推出，该版本的推出，填补了LayaAir引擎在3D编辑器上的不足，不再依赖第三方3D编辑器，从此实现了2D+3D游戏的一站式开发。建立了从引擎到工具链的完整工作流。从引擎上，不仅升级了底层架构，更是面向未来的支持了新一代的图形API--WebGPU。在Native APP层更是支持了C++的引擎算法与渲染，以及开发者自定义的C++算法。无论是引擎的设计，还是IDE的设计，都是突破性阶段的发展。": "On June 30, 2023, the official version of LayaAir3.0 was released. The launch of this version filled the gap in LayaAir engine's 3D editor, eliminating the dependence on third-party 3D editors and achieving one-stop development for both 2D and 3D games. A complete workflow from the engine to the toolchain was established. In terms of the engine, not only was the underlying architecture upgraded, but it also supported the new generation graphics API—WebGPU, looking towards the future. On the Native APP layer, it supported the engine algorithms and rendering in C++, as well as developer-customized C++ algorithms. Both the design of the engine and the IDE represent a breakthrough stage of development.",
  "2023年11月，LayaAir引擎团队与掌趣科技合作，发布LayaAir 3.1版本——正式成为一款具备AI生产力的游戏研发引擎，全面拥抱AIGC（人工智能生成内容）。引擎支持AI创作生成、编辑器的AI控制、引擎技术AI客服等AI插件能力。未来，LayaAir将在保障在国产Web 3D引擎渲染能力持续领先的基础上，继续深入与掌趣科技的合作，大力发展AIGC与3D引擎的结合，定位“最易用的AIGC引擎生态”。": "In November 2023, the LayaAir engine team collaborated with Ourpalm Technology to release LayaAir 3.1 version—officially becoming a game development engine with AI productivity, fully embracing AIGC (Artificial Intelligence Generated Content). The engine supports AI-powered content creation, AI control in the editor, and AI customer service for engine technology, among other AI plugin capabilities. In the future, LayaAir will continue to deepen its cooperation with PalmFun Technology on the basis of ensuring its leading position in domestic Web 3D engine rendering capabilities, vigorously develop the integration of AIGC and 3D engines, and position itself as \"the most user-friendly AIGC engine ecosystem.\"",
  "引擎代码概述": "Engine Code Overview",
  "引擎代码，除了开放式的可编程的渲染管线，全平台的图形引擎架构，次世代PBR渲染流，ClusterLighting多光源技术，Forward+渲染管线，高性能并行渲染器API的接入（WebGPU）等核心基础。对于开发者而言，我们从通用、2D、3D，这三个部分来分别介绍。": "Engine code, in addition to the open programmable rendering pipeline, cross-platform graphics engine architecture, next-generation PBR rendering flow, ClusterLighting multi-light source technology, Forward+ rendering pipeline, and the integration of high-performance parallel renderer API (WebGPU) and other core foundations. For developers, we introduce them separately from three parts: general, 2D, and 3D.",
  "引擎通用部分": "Engine common parts",
  "网络（HTTP请求、WebSocket请求）": "Network (HTTP requests, WebSocket requests)",
  "加载（可加载文本、JSON、XML、二进制、音频、视频、骨骼文件、图像文件等资源）": "Loading (loadable text, JSON, XML, binary, audio, video, skeletal files, image files, and other resources)",
  "ECS组件系统（组件系统、生命周期方法）": "ECS component system (component system, lifecycle methods)",
  "场景管理": "Scene management",
  "事件（派发、侦听，捕获）": "Event (dispatch, listen, capture)",
  "交互（鼠标、键盘、屏幕touch、VR手柄）": "Interaction (mouse, keyboard, screen touch, VR controller)",
  "数学方法": "Mathematical methods",
  "多媒体播放（音频、视频）": "Multimedia playback (audio, video)",
  "缓动": "tween",
  "浏览器接口（封装常用的浏览器window功能、调用浏览器window功能）": "Browser interface (encapsulates commonly used browser window functions, calls browser window functions)",
  "设备接口（陀螺仪、加速计、地理位置）": "Device Interface (Gyroscope, Accelerometer, Geolocation)",
  "节点": "Node",
  "屏幕适配": "Screen adaptation",
  "小游戏适配（微信、手机QQ、字节跳动小游戏、OPPO、vivo、……等）": "Mini-game adaptation (WeChat, Mobile QQ, ByteDance Mini Games, OPPO, vivo, etc.)",
  "2D引擎": "2D engine",
  "2D精灵（2D基础显示对象与容器）": "2D sprites (2D basic display objects and containers)",
  "2D视图（视窗、弹窗）": "2D view (Scene2D, Dialog)",
  "2D动画（图集动画、逐帧动画、缓动动画、时间轴动画、龙骨动画、spine动画）": "2D animation (atlas animation, frame-by-frame animation, easing animation, timeline animation, dragon bone, spine bone)",
  "2D文本（基础文本、HTML文本、BitmapFont位图字体）": "2D text (text, HTML text, BitmapFont)",
  "2D UI组件（图像、按钮、显示文本、文本输入、文本域、下拉框、多选框、单选框、单选框组、导航标签组、导航容器、位图切片、位图字体切片、垂直滚动条、水平滚动条、进度条、垂直划动条、水平划动条、取色器、基础容器、列表、树状列表、面板容器）": "2D UI components (Image, Button, Label, TextInput, TextArea, Combobox, CheckBox, Radio, RadioGroup, Tab, ViewStack, Clip, FontClip, VScrollBar, HcrollBar, Progressbar, VSlider, HSlider, ColorPicker, Box, List, Tree, Panel)",
  "2D UI效果（遮罩、滤镜）": "2D UI effects (mask, filter)",
  "2D场景继承类（管理UI）": "2D scene inheritance class (runtime class)",
  "2D绘图（绘制矩形与圆角矩形、绘制圆形与扇形、绘制多边形、绘制线段、绘制折线、绘制曲线、绘制纹理与填充纹理）": "2D drawing (drawing rectangles and rounded rectangles, circles and sectors, polygons, line segments, polylines, curves, textures and filled textures)",
  "2D物理（Box2D）": "2D physics (Box2D)",
  "Tiled Map地图": "Tiled Map",
  "3D引擎": "3D engine",
  "3D精灵（3D基础显示对象与容器）": "3D sprite (3D basic display object and container)",
  "3D基础工具（3D坐标系、3D变换、3D数学工具、等）": "3D Basic Tools (3D Coordinate System, 3D Transformations, 3D Mathematical Tools, etc.)",
  "3D场景（场景管理、环境光、环境反射、场景天空、场景雾、等）": "3D scene (scene management, ambient light, ambient reflection, scene sky, scene fog, etc.)",
  "3D摄像机": "3D camera",
  "3D光照（方向光、点光、聚光、区域光、阴影、光效）": "3D lighting (directional light, point light, spotlight, area light, shadows, light effects)",
  "3D网格": "3D mesh",
  "3D材质（模型材质、粒子材质、拖尾材质、天空材质）": "3D materials (model materials, particle materials, trail materials, sky materials)",
  "3D纹理": "3D texture",
  "3D粒子系统": "3D particle system",
  "3D拖尾": "3D trailing",
  "3D物理（bullet 、Cannon.js、PhysX）": "3D Physics (bullet, Cannon.js, PhysX)",
  "3D动画（刚体动画、材质动画、骨骼动画、摄像机动画、时间轴动画）": "3D animation (rigid body animation, material animation, skeletal animation, camera animation, timeline animation)",
  "自定义Shader": "Custom Shader",
  "webXR": "webXR",

  // productpage_LayaAirIDE
  "IDE发展简介": "Introduction to LayaAir IDE Development",
  "IDE是Integrated Development Environment（集成开发环境）的缩写。": "IDE is an abbreviation for Integrated Development Environment.",
  "LayaAir 1.0与2.0的 IDE，主要提供了项目的创建与管理、UI编辑、动画编辑、多平台发布与构建、综合工具包（图集打包、骨骼动画转换、纹理压缩、文件压缩等），这些2D开发的工作流。": "LayaAir 1.0 and 2.0 IDEs mainly provide project creation and management, UI editing, animation editing, multi-platform publishing and building, and a comprehensive toolkit (atlas packaging, bone animation conversion, texture compression, file compression, etc.), which are workflows for 2D development.",
  "2021年，Layabox全资收购了游戏行业知名的UI编辑器FairyGUI。并由FairyGUI的作者担任LayaAir3.0 IDE的研发负责人。": "In 2021, Layabox fully acquired the well-known UI editor in the gaming industry, FairyGUI. The author of FairyGUI took on the role of the research and development lead for LayaAir 3.0 IDE.",
  "LayaAir3.0 IDE 在继承了LayaAir2.0 UI编辑器核心工作流的基础上。优化了2D编辑器，增加了3D编辑器的各项功能模块，以及资源商城等生态模块。": "LayaAir3.0 IDE, building on the foundation of the LayaAir2.0 UI editor's core workflow, has optimized the 2D editor, added various functional modules to the 3D editor, as well as ecological modules such as the asset store.",
  "LayaAir引擎从3.0的IDE开始，搭建了引擎2D与3D一站式集成开发环境，完全满足开发者使用LayaAir引擎的各种可视化开发需求。": "Starting from the 3.0 IDE, the LayaAir engine has built a one-stop integrated development environment for both 2D and 3D, fully meeting the various visual development needs of developers using the LayaAir engine.",
  "IDE主要功能模块概述": "Overview of the main functional modules of the IDE",
  "IDE的通用功能": "Common features of IDEs",
  "层级面板": "Hierarchy panel",
  "项目资源面板": "Assets panel",
  "场景面板": "Scene window",
  "构建发布面板": "Build panel",
  "控制台面板": "Console panel",
  "属性设置面板": "Inspector panel",
  "预览运行面板": "Game panel",
  "LayaAir引擎资源商店": "Assets store",
  "LayaAir IDE插件系统": "IDE plug-in System",
  "IDE的2D功能模块": "IDE's 2D function module",
  "UI编辑模块": "UI Editing Module",
  "2D动画编辑模块": "2D animation editing module",
  "2D预制体模块": "2D Prefab Module",
  "2D物理编辑模块": "2D Physics Editing Module",
  "2D组件模块": "2D component module",
  "IDE的3D功能模块": "IDE's 3D function module",
  "3D场景编辑模块": "3D Scene Editing Module",
  "3D材质编辑模块": "3D Material Editing Module",
  "3D蓝图编辑模块": "3D Blueprint Editing Module",
  "3D粒子编辑模块": "3D Particle Editing Module",
  "3D物理编辑模块": "3D Physics Editing Module",
  "3D动画编辑模块": "3D Animation Editing Module",
  "3D烘焙模块": "3D Baking Module",

  // productpage_LayaAirNative
  "LayaAir Native简介": "LayaAir Native Introduction",
  "LayaAir Native是LayaAir引擎针对移动端原生APP的开发、测试、发布的一套完整的开发解决方案。": "LayaAir Native is a complete development solution for the development, testing, and publishing of mobile native APPs targeted by the LayaAir engine.",
  "LayaAir Native基于C++的运行时，封装了原生的图形API（例如OpenGL ES），支持LayaAir引擎产品可打包成为APP上架移动端APP平台。": "LayaAir Native is based on the C++ runtime, encapsulating native graphics APIs (such as OpenGL ES), and supports packaging LayaAir engine products into APPs for listing on mobile APP platforms.",
  "还支持反射机制，提供开发者在原生APP上进行二次开发和渠道对接的自定义扩展。": "It also supports reflection mechanism, providing developers with custom extensions for secondary development and channel docking on native APPs.",
  "LayaAir Native的3.0版本，更是进行了大量的重构，在面向未来，以及开发者的扩展自由度上，进一步得到了加强。": "The 3.0 version of LayaAir Native has undergone a large amount of refactoring, further strengthening its orientation towards the future and the developers' freedom to extend.",
  "例如，在渲染内核方面，除了OpenGL ES，新增支持Vulkan、Metal。": "For example, in terms of rendering engines, in addition to OpenGL ES, there is added support for Vulkan, Metal.",
  "在核心算法层里，开发者可以直接用C++的算法，直接接管替换引擎的JS算法。": "In the core algorithm layer, developers can directly use C++ algorithms to take over and replace the engine's JS algorithms.",
  "LayaAir Native 3.0包括以下主要功能：": "LayaAir Native 3.0 includes the following main features:",
  "LayaPlayer": "LayaPlayer",
  "LayaPlayer是LayaNative核心的部分，它是一个基于JavaScript脚本引擎 + OpenGL ES硬件加速渲染的跨平台C++引擎，通过对内存与渲染流程进行极致优化，为LayaAir引擎脱离浏览器成为APP提供独立的底层运行环境。": "LayaPlayer is a part of the LayaNative core. It is a cross-platform C++ engine based on the JavaScript scripting engine + OpenGL ES hardware-accelerated rendering. Through extreme optimization of memory and rendering processes, it provides an independent underlying running environment for the LayaAir engine to become an APP separate from the browser.",
  "需要注意的是，LayaPlayer不等于浏览器，仅为LayaAir引擎运行提供支撑，不支持普通web链接的运行。": "It should be noted that LayaPlayer is not equivalent to a browser; it only provides support for the operation of the LayaAir engine and does not support the running of ordinary web links.",
  "构建工具": "Building tools",
  "构建工具可帮助开发者快速构建移动端APP项目工程，然后使用Android Studio、Eclipce、XCode 等开发工具打开->构建->运行；": "Construction tools can help developers quickly build mobile APP project engineering, and then use development tools such as Android Studio, Eclipse, XCode to open -> build -> run",
  "反射机制": "Reflective mechanism",
  "通过反射机制，开发者可以实现JavaScript与原生语言(Android/Java 或 iOS/Objective-C)的相互调用，通过反射机制开发者可以很方便的对应用程序进行二次扩展；": "Through the reflection mechanism, developers can implement mutual calls between JavaScript and native languages (Android/Java or iOS/Objective-C), and the reflection mechanism allows developers to easily carry out secondary expansion of the application",

  // productpage_LayaAirTech
  "LayaAir引擎免费服务": "LayaAir engine free service",
  "LayaAir引擎为行业提供了免费的开源引擎与IDE，以及免费的文档、API、示例、社区等基础服务。": "The LayaAir engine provides the industry with a free open-source engine and IDE, as well as free documentation, APIs, examples, community, and other basic services.",
  "开源引擎:": "Open source engine: ",
  "免费文档:": "Free documentation: ",
  "免费API:": "Free API: ",
  "免费Demo:": "Free Demo: ",
  "开发者社区:": "Developer Community: ",
  "LayaAir引擎VIP定制服务": "LayaAir Engine VIP Customization Service",
  "除了免费的基础服务外，如果开发者产生更多一对一技术服务需求。例如引擎使用答疑、BUG优先处理、引擎功能定制等、项目性能优化、一对一教学培训等需求。": "In addition to the free basic services, if developers have more one-on-one technical service needs. For example, engine usage Q&A, priority bug handling, engine feature customization, project performance optimization, one-on-one teaching and training, etc.",
  "可与我们联系，针对具体需求，提供定制化的技术服务。": "You can contact us to provide customized technical services for specific needs.",
  "微信帐号:LayaAir_Engine": "WeChat account: LayaAir_Engine",

  // 首页
  "蓝亚宇宙": "Layaverse",
  "蓝亚宇宙是基于LayaAir引擎研发的元宇宙平台，该平台拥有众多商业级别元宇宙成功案例，例如《云上国漫城》、《南开大学数字元空间》、《国家电网廉政展厅元宇宙空间》、《工信部工业元宇宙协同发展组织元宇宙空间》等等。": "The Layaverse is a metaverse platform based on the LayaAir engine. This platform has numerous commercial-level metaverse success cases, such as Cloud on the Comic City, Nankai University Digital Meta Space, State Grid's Anti-corruption Exhibition Metaverse Space, and the Metaverse Collaborative Development Organization of the Ministry of Industry.",
  "穿越火线（Cross Fire，简称CF）是经典的知名游戏IP，由腾讯采用LayaAir引擎研发，2020年移植到微信小游戏平台的CF正版FPS手游。": "Cross Fire (CF) is a classic well-known game IP, developed by Tencent using the LayaAir engine, and ported to WeChat Mini Game platform in 2020. It is a licensed FPS mobile game.",
  "王者别嚣张是一款基于LayaAir引擎的3D MOBA竞技对决游戏。公平对战、5V5王者峡谷，精彩的对局玩法，爽快的竞技过程，极速匹配为你找寻实力相当的对手同场竞技，享受刺激精彩的冒险挑战，还原MOBA经典体验！": "King's Barely Oppose is a 3D MOBA competitive game based on the LayaAir engine. Fair play, 5V5 King's Valley, exciting battleground gameplay, fast-paced competitive process, lightning-fast matching to find opponents of equal strength, enjoy the exciting and thrilling adventure challenge, and recreate the classic MOBA experience!",
  "跳舞的线小游戏版，是一款基于LayaAir引擎的集快节奏操控与精选音轨于一身的节奏敏捷游戏。玩家不仅需要双眼来观察障碍物与陷阱，更需用双耳聆听节奏，旋律与节奏会帮助玩家走得更远！": "Dancing Line is a rhythm game based on the LayaAir engine that combines fast-paced operation and selected audio tracks. Players not only need to use their eyes to observe obstacles and traps, but also need to listen to the rhythm and melody with both ears. The rhythm and melody will help players go further!",
  "《第7装甲师》小游戏版，采用LayaAir引擎研发，高度还原现代战争，逼真至极！酷炫合击战斗画面，全屏燃爆！畅快改装，任意设计自己的坦克，加入PVP多人战场团战，战力飙升傲视全服！": "The 7th Armored Division is a game version based on LayaAir engine research and development, which fully replicates modern warfare, and is extremely realistic! Cool and realistic combat graphics, full-screen explosions! Free to customize and design your own tank, join the PVP multiplayer battle team, and rise in power and conquer the entire server!",
  "消灭病毒是由青岛蓝飞互娱采用LayaAir引擎制作的爆款产品，游戏的灵感源自一部叫《工作细胞》的动画。玩家只需要控制飞机移动并击破屏幕上方不停分裂的数字“病毒”即可。上手简单趣味无穷，火爆各平台。": "Eliminate the virus is a popular product made by Qingdao Lanfei Interactive Entertainment using the LayaAir engine. The inspiration for the game comes from an animation called \"Cell Division\". All you have to do is control the plane to move and destroy the numbers \"virus\" that keep splitting on the screen above. It's easy to get started and full of fun, and it's hot on all platforms.",
  "奇迹MU正版授权魔幻大作《大天使之剑》，三七游戏采用LayaAir引擎研发，月流水破亿人民币的爆款产品。完美传承暗黑魔幻玩法，新增野外探险，1人控团杀BOSS等玩法。": "Miracle MU is a copyrighted work of Sanqi Games, which uses the LayaAir engine to research and develop. It is a popular product with a monthly sales volume of more than one billion RMB. It perfectly inherits the dark fantasy play, and adds outdoor exploration, 1 person control teamkill BOSS and other play.",
  "格斗战机是一款基于LayaAir引擎研发的二战题材空战射击游戏。该作一改传统飞机游戏的竖版躲子弹模式，让飞机在各方向都能够自由飞行，通过咬尾攻击，达到真实空战的效果，同时实现了“大筋斗”、破“S”机动，上升回旋、急转弯 等空中格斗机动动作。": "Fighting Fighter Jet is a WWII-themed air combat shooting game based on the LayaAir engine. This game departs from the traditional vertical mode of hiding bullets in the plane game, allowing the plane to freely fly in all directions, and achieve the real air combat effect by biting the tail attack, and achieves \"big stretch\" and \"break S\" manoeuvres, rising back and spinning, and rapid turns and other air combat manoeuvres.",
  "全民大乐斗是腾讯魔镜工作室采用LayaAir引擎自研的回合制游戏。Q版卡通题材，玩家代表一只胸怀武林梦想的企鹅，经历各种洗礼和挑战最终实现自己的武林梦想。": "Public Big Martial Arts is a turn-based game developed by Tencent Magic Mirror Studio using LayaAir engine. The cartoon theme is Q-version, and the player represents a penguin with a dream of the martial arts world, who goes through various experiences and challenges and finally achieves his own martial arts dream.",
  "全民疯狂战机": "Public Crazy Fighter Jet",
  "全民疯狂战机采用LayaAir引擎研发，是一款应用了部分3D引擎技术的2D飞行射击游戏，完美的3D效果与2D体验混合，打造出华丽弹幕、炫酷特效的视觉盛宴！": "Public Crazy Fighter Jet uses LayaAir engine to develop a 2D flying shooting game that applies part of the 3D engine technology. A beautiful visual feast is created by mixing the 3D effect and the 2D experience, with dazzling effects and gorgeous bullet screens!",
  "LayaAir 3 优势": "LayaAir 3 highlights",
  "LayaAir 3 advantages": "LayaAir 3 advantages",
  "极致性能": "Excellent performance",
  "优异的性能一直是LayaAir引擎的核心追求，永无止境，极致追求。": "Excellent performance has always been the core pursuit of the LayaAir engine, without rest, and pursuit of extreme.",
  "领跑Web 3D引擎": "Leading Web 3D engine",
  "LayaAir自2016年推出3D引擎以来，多年商业案例技术积累，是基于web 图形API最成熟的国产开源3D引擎。在行业中已达成共识：开发3D小游戏就用LayaAir 。": "LayaAir has been launching 3D engines since 2016, and has accumulated years of commercial case technical accumulation based on the most mature domestic and open source 3D engine for web graphics API. In the industry, it has reached consensus that 3D small games are developed using LayaAir . ",
  "完善工具链+AIGC": "Perfect toolchain + AIGC",
  "支持UI编辑器、3D场景编辑器、3D材质编辑器、3D粒子编辑器、3D蓝图编辑器、动画编辑器(2D+3D)、物理编辑器(2D+3D)、插件系统、资源商店、代码与美术设计分离等，以及AI创作生成、编辑器AI控制、引擎技术AI客服等AI插件能力！": "Support UI editor, 3D scene editor, 3D material editor, 3D particle editor, 3D blueprint editor, animation editor (2D + 3D), physics editor (2D + 3D), plugin system, resource store, code and art design separation, and AI creation and generation, editor AI control, and AI customer service capabilities of the engine technology! ",
  "全平台发布": "Multi-platform",
  "一次开发，可同时发布Web版、安装包（安卓与iOS）、小游戏平台。": "Once developed, it can be released simultaneously on the Web version, the installation package (Android and iOS), and the small game platform.",
  "行业优选": "Industry Preference",
  "拥有超百万的开发者生态，腾讯、阿里巴巴、网易、三七互娱、掌趣科技、猎豹移动、汤姆猫、商汤科技、电魂网络等大型企业选用的引擎。": "It has a super-million developer ecosystem, and is used by Tencent, Alibaba, NetEase, Tencent Games, QiyouHu, Zhangyu Technology, Shuangyan Mobile, TOM, Shangtang Technology, and other large enterprises. ",
  "应用广泛": "Widely Applied",
  "适用于游戏、广告营销、教育、动画、元宇宙、数字人、数字孪生、智慧城市、工业控制、仿真模拟、国防模拟、线上展示（展厅、看房、旅行……）等众多3D 数字领域。": "Applicable to games, advertising marketing, education, animation, metaverse, digital people, digital twins, smart city, industrial control, simulation simulation, defense simulation, and online display (exhibition, viewing, travel, etc.) in many 3D digital fields.",
  "引擎开发者": "Engine Developer",
  "引擎合作伙伴": "Engine Partner",

  // 头部
  "首页": "Home",
  "产品": "Products",
  "动态": "News",
  "案例": "Showcase",
  "引擎社区": "Community",
  "资源商店": "Store",
  "API": "API",
  "文档": "Documentation",
  "示例": "Example",
  "引擎下载": "Download",
  "全文检索": "Search",
  "语言切换": "Language",

  // 底部
  "其他服务": "Other Services",
  "教学视频": "Learning",
  "Github源码": "Github",
  "Gitee源码": "Gitee",
  "LayaAir2.0引擎": "LayaAir2.0 Engine",
  "关于我们": "About Us",
  "人才招聘": "Career",
  "隐私政策": "Privacy Policy",
  "用户协议": "Terms Of Service",
  "友情链接：": "Partner Sites:"
};

