
import { debounce } from "@/utils/index";

export default {
  name: "app",
  components: {
    // Header,
  },
  created() {
    window.addEventListener("resize", debounce(this["resetSize"], 200, false));
  },
  methods: {
    resetSize() {
      this["$store"].commit("updateWindowWidth");
    },
  },
};
