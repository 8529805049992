<template>
  <div class="tabs">
    <div ref="nav" class="tabs-nav" :style="navStyle">
      <div class="tabs-inv-bar" :style="barStyle"></div>
      <div
        class="tabs-tab"
        :class="{ active: activeKey == item.name }"
        v-for="(item, index) in navList"
        :key="'image' + index"
        @click="handleChange(index)"
      >
        <img :src="item.subSrc" />
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { debounce } from "@/utils/index";
export default {
  name: "ImageTabs",
  provide() {
    return { TabsInstance: this };
  },
  props: {
    value: {
      type: [String, Number],
    },
    isShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      navList: [],
      activeKey: this.value,
      barOffset: 0,
      navStyle: {
        transform: "",
      },
      debounceFunc: debounce(this.resetSize, 200, false),
    };
  },
  created() {
    window.addEventListener("resize", this.debounceFunc);
  },
  destroyed() {
    window.removeEventListener("resize", this.debounceFunc);
  },
  computed: {
    barStyle() {
      return {
        transform: `translate3d(0px,${this.barOffset}px,0px)`,
      };
    },
  },
  methods: {
    getTabs() {
      return this.$children.filter(
        (item) => item.$options.name === "ImageTabPane"
      );
    },
    initTabs() {
      this.updateNav();
      this.updateStatus();
      this.updataBar();
    },
    resetSize() {
      this.updataBar();
    },
    updateNav() {
      this.navList = [];
      this.getTabs().forEach((pane, index) => {
        this.navList.push({
          subSrc: pane.subSrc,
          name: pane.name || index,
        });
        if (index === 0 && !this.activeKey) {
          this.activeKey = pane.name;
        }
      });
    },

    updataBar() {
      this.$nextTick(() => {
        const index = this.navList.findIndex(
          (nav) => nav.name === this.activeKey
        );
        const elemTabs = this.$refs.nav.querySelectorAll(".tabs-tab");
        const elemTab = elemTabs[index];
        this.barOffset = elemTab ? elemTab.offsetTop : 0;
      });
    },
    updateStatus() {
      const tabs = this.getTabs();
      tabs.forEach((tab) => (tab.show = tab.name === this.activeKey));
    },
    handleChange(index) {
      const nav = this.navList[index];
      this.activeKey = nav.name;
    },
  },
  watch: {
    value(val) {
      this.activeKey = val;
    },
    activeKey() {
      this.updateStatus();
      this.updataBar();
    },
    isShow(e) {
      if (e) {
        this.updataBar();
      }
    },
  },
};
</script>
<style scoped>
.tabs {
  display: flex;
  position: relative;
}
.code {
  position: absolute;
  top: 3rem;
  z-index: 1;
  left: 24%;
}
.code img {
  width: 2rem;
}
.tabs-tab {
  width: 2.54rem;
  height: 1.34rem;
  margin: 0.1rem 0.16rem 0;
  cursor: pointer;
  font-size: 0.32rem;
  background: red;
  border-radius: 0.1rem;
  overflow: hidden;
}
.tabs-tab img {
  width: 100%;
  height: 100%;
}
.tabs-inv-bar {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0.16rem;
  width: 2.54rem;
  height: 1.34rem;
  border: 0.05rem #08427e solid;
  border-radius: 0.1rem;
  /* transition: transform 300ms ease-in-out; */
}
/* .active {
  color: #63acff;
} */
.tabs-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 2.86rem;
  height: 7.4rem;
  padding: 0.05rem 0 0;
  border-radius: 0.1rem;
  background-color: #f0f0f0;
}
</style>
