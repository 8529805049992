<template>
  <div v-show="show">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "ImageTabPane",
  inject: ["TabsInstance"],
  props: {
    name: {
      type: String,
    },
    subSrc: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    this.TabsInstance.initTabs();
  },
  watch: {
    subSrc() {
      this.TabsInstance.initTabs();
    },
    name() {
      this.TabsInstance.initTabs();
    },
  },
};
</script>
