
import { Component, Prop, Vue } from "vue-property-decorator";
export type CardData = {
  src: string;
  title: string;
  subTitle: string;
  text: string;
  icon: string;
  isShow: boolean;
};
@Component
export default class Card extends Vue {
  @Prop() data!: CardData;
  // isShow = false;
}
