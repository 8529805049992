<template>
  <div class="tabs">
    <div ref="navWrap" class="tabs-nav-wrap">
      <div ref="nav" class="tabs-nav" :style="navStyle">
        <div class="tabs-inv-bar" :style="barStyle"></div>
        <div
          class="tabs-tab"
          :class="{ active: activeKey == item.name }"
          v-for="(item, index) in navList"
          :key="index"
          @click="handleChange(index)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { debounce } from "@/utils/index";

export default {
  name: "TABS",
  provide() {
    return { TabsInstance: this };
  },
  props: {
    value: {
      type: [String, Number],
    },
    onchange: {
      type: Function,
    },
  },
  data() {
    return {
      navList: [],
      activeKey: this.value,
      barWidth: 0,
      barOffset: 0,
      navStyle: {
        transform: "",
      },
      debounceFunc: debounce(this.resetSize, 200, false),
    };
  },
  created() {
    window.addEventListener("resize", this.debounceFunc);
  },
  destroyed() {
    window.removeEventListener("resize", this.debounceFunc);
  },
  computed: {
    barStyle() {
      return {
        width: `${this.barWidth}px`,
        transform: `translate3d(${this.barOffset}px,0px,0px)`,
      };
    },
  },
  methods: {
    getTabs() {
      return this.$children.filter((item) => item.$options.name === "TabPane");
    },
    resetSize() {
      this.updataBar();
    },
    initTabs() {
      this.updateNav();
      this.updateStatus();
      this.updataBar();
    },
    updateNav() {
      this.navList = [];
      this.getTabs().forEach((pane, index) => {
        this.navList.push({
          label: pane.label,
          name: pane.name || index,
        });
        if (index === 0 && !this.activeKey) {
          this.activeKey = pane.name;
        }
      });
    },
    updataBar() {
      this.$nextTick(() => {
        const index = this.navList.findIndex(
          (nav) => nav.name === this.activeKey
        );
        const elemTabs = this.$refs.navWrap.querySelectorAll(".tabs-tab");
        const elemTab = elemTabs[index];
        this.barWidth = elemTab ? elemTab.offsetWidth : 0;
        this.barOffset = elemTab ? elemTab.offsetLeft : 0;
      });
    },
    updateStatus() {
      const tabs = this.getTabs();
      tabs.forEach((tab) => (tab.show = tab.name === this.activeKey));
    },
    handleChange(index) {
      const nav = this.navList[index];
      this.activeKey = nav.name;
    },
  },
  watch: {
    value(val) {
      this.activeKey = val;
    },
    activeKey() {
      this.updateStatus();
      this.updataBar();
      this.onchange && this.onchange(this.activeKey);
    },
  },
};
</script>
<style scoped>
.tabs-nav-wrap {
  position: relative;
  margin: 0 auto 0;
}
.tabs-tab {
  position: relative;
  display: inline-block;
  margin: 0 0.4rem;
  padding: 0.05rem 0;
  cursor: pointer;
  font-size: 0.32rem;
  font-weight: 500;
}
.tabs-inv-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #63acff;
  height: 0.04rem;
  transition: transform 300ms ease-in-out;
}
.active {
  color: #63acff;
}
.tabs-nav {
  position: relative;
  transition: transform 0.5s ease-in-out;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 760px) {
  .tabs-tab {
    font-size: 0.6rem;
    padding: 0.15rem 0;
    margin: 0 1.2rem;
  }
  .tabs-inv-bar {
    height: 0.1rem;
  }
}
</style>
