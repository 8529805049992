
import { Component, Vue } from "vue-property-decorator";
import Licence from "@/components/Licence.vue";

@Component({
  components: {
    Licence,
  },
})
export default class Fooder extends Vue {

  get windowWidth() {
    return this.$store.state.windowWidth;
  }
  goto(str: string) {
    window.open(str);
  }
  winOpen(url: string) {
    window.open(url);
  }
  isAir() {
    let arr = ["layaair.com", "www.layaair.com"];
    if (arr.indexOf(window.location.hostname) > -1) {
      return true;
    }
    return false;
  }
  jumpBeian() {
    window.open("https://beian.miit.gov.cn/");
  }
  jumpTradeMark() {
    this.$router.push({
      path: "/tradeMark",
    });
  }
}
