
import { Component, Watch, Vue } from "vue-property-decorator";
import { localSave } from "@/utils/index";
type ButtonConfig = {
  name: string;
  link: string;
  children?: ButtonConfig[];
};
@Component
export default class Header extends Vue {
  languageMap = {
    zh: "中文",
    en: "English",
  };

  buttonLeft: ButtonConfig[] = [
    {
      name: "首页",
      link: "/",
    },
    {
      name: "产品",
      link: "/productpage",
      children: [
        {
          name: "LayaAir 引擎",
          link: "/productpage/LayaAirEngine",
        },
        {
          name: "LayaAir IDE",
          link: "/productpage/LayaAirIDE",
        },
        {
          name: "LayaAir Native",
          link: "/productpage/LayaAirNative",
        },
        {
          name: "LayaAir 技术服务",
          link: "/productpage/LayaAirTech",
        },
      ],
    },
    {
      name: "动态",
      link: "/enginedynamics",
    },
    {
      name: "案例",
      link: "/enginedemo",
    },
    {
      name: "引擎社区",
      link:
        this.$i18n.locale == "zh"
          ? "https://ask.layabox.com"
          : "https://discord.com/channels/1211921905775411220/",
    },
    {
      name: "资源商店",
      link: "https://store.layaair.com",
    },
  ];
  buttonRight: ButtonConfig[] = [
    {
      name: "API",
      link: "/api",
    },
    {
      name: "文档",
      link: "/doc",
    },
    {
      name: "示例",
      link: "/demo",
    },
    {
      name: "引擎下载",
      link: "/engineDownload",
    },
  ];
  mobileSelcetLanguage = false;
  listData = this.buttonLeft.concat(this.buttonRight);
  childrenList: ButtonConfig[] = [];
  searchString?: string;
  show = false;
  currentPath = this.$router.currentRoute.fullPath;
  parentUrl = "/" + this.$router.currentRoute.fullPath.split("/")[1];
  created() {
    this.listData.forEach((item) => {
      if (item.link === this.parentUrl) {
        this.childrenList = item.children || [];
      }
    });
  }
  data() {
    return {
      searchString: "",
    };
  }
  onShow() {
    this.show = !this.show;
    this.mobileSelcetLanguage = false;
  }
  onListClick(item: ButtonConfig) {
    this.mobileSelcetLanguage = false;
    if (item.children) {
      this.childrenList = item.children;
      this.parentUrl = item.link;
    } else {
      this.linkTo(item);
      // this.$router.push(item.link);
      this.show = false;
      this.currentPath = item.link;
    }
  }
  linkTo(row: ButtonConfig) {
    this.show = false;
    if (row.link.startsWith("http")) {
      window.open(row.link);
    } else {
      this.$router.push(row.link);
    }
  }
  onSearchChange(str: string) {
    window.open("https://sou.layabox.com/?language=zh&f=_all&q=" + str);
  }
  @Watch("$route")
  onRouter(n, o) {
    this.currentPath = n.fullPath;
  }
  changeLanguage(lang: string) {
    this.$i18n.locale = lang;
    localSave("locale", lang);
    this.show = false;
    // localStorage.setItem("language", lang);
  }
  onChangeLanguage() {
    this.mobileSelcetLanguage = true;
  }
  @Watch("$i18n.locale")
  async onChangeLocale() {
    this.buttonLeft[4].link =
      this.$i18n.locale == "zh"
        ? "https://ask.layabox.com"
        : "https://discord.com/channels/1211921905775411220/";
  }
}
