
import { Component, Vue } from "vue-property-decorator";
import Card, { CardData } from "./Card.vue";

@Component({
  components: { Card },
})
export default class HomeTabContent extends Vue {
  data: CardData[] = [
    {
      src: require("@/assets/advantage1.png"),
      title: "极致性能",
      subTitle: "极致性能",
      text: "优异的性能一直是LayaAir引擎的核心追求，永无止境，极致追求。",
      icon: require("@/assets/xitonggongju.png"),
      isShow: false,
    },
    {
      src: require("@/assets/advantage2.png"),
      title: "领跑Web 3D引擎",
      subTitle: "领跑Web 3D引擎",
      text: "LayaAir自2016年推出3D引擎以来，多年商业案例技术积累，是基于web 图形API最成熟的国产开源3D引擎。在行业中已达成共识：开发3D小游戏就用LayaAir 。",
      icon: require("@/assets/xitonggongju.png"),
      isShow: false,
    },
    {
      src: require("@/assets/advantage3.png"),
      title: "完善工具链+AIGC",
      subTitle: "完善工具链+AIGC",
      text: "支持UI编辑器、3D场景编辑器、3D材质编辑器、3D粒子编辑器、3D蓝图编辑器、动画编辑器(2D+3D)、物理编辑器(2D+3D)、插件系统、资源商店、代码与美术设计分离等，以及AI创作生成、编辑器AI控制、引擎技术AI客服等AI插件能力！",
      icon: require("@/assets/xitonggongju.png"),
      isShow: true,
    },
    {
      src: require("@/assets/advantage4.png"),
      title: "全平台发布",
      subTitle: "全平台发布",
      text: "一次开发，可同时发布Web版、安装包（安卓与iOS）、小游戏平台。",
      icon: require("@/assets/xitonggongju.png"),
      isShow: false,
    },
    {
      src: require("@/assets/advantage5.png"),
      title: "行业优选",
      subTitle: "行业优选",
      text: "拥有超百万的开发者生态，腾讯、阿里巴巴、网易、三七互娱、掌趣科技、猎豹移动、汤姆猫、商汤科技、电魂网络等大型企业选用的引擎。",
      icon: require("@/assets/xitonggongju.png"),
      isShow: false,
    },
    {
      src: require("@/assets/advantage6.png"),
      title: "应用广泛",
      subTitle: "应用广泛",
      text: "适用于游戏、广告营销、教育、动画、元宇宙、数字人、数字孪生、智慧城市、工业控制、仿真模拟、国防模拟、线上展示（展厅、看房、旅行……）等众多3D 数字领域。",
      icon: require("@/assets/xitonggongju.png"),
      isShow: false,
    },
  ];

  show(selectIndex) {
    this.data.map((item, index) => {
      if (index == selectIndex) {
        item.isShow = true;
      } else {
        item.isShow = false;
      }
      return item;
    });
  }
  hide(index) {
    this.data[index].isShow = false;
  }
}
