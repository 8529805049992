
import { videoPlayer } from "vue-video-player";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import homedata from "@/config/home.config";
export type HomeTabContentType = {
  bigImg: string;
  phoneImg: string;
  lateralImg: string;
  title: string;
  txt: string;
  code: string;
};
@Component
export default class HomeTabContent extends Vue {
  // @Prop() data!: HomeTabContentType;

  // playVideo = this.data.lateralImg ? this.data.lateralImg : this.data.phoneImg;
  // get player() {
  //   return this.$refs.videoPlayer.player;
  // }
  // video = this.data;
  video: HomeTabContentType = {
    bigImg: "",
    phoneImg: "",
    title: "",
    txt: "",
    code: "",
    lateralImg: "",
  };
  playerImgOptions = {
    // fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    autoplay: true, //如果true,浏览器准备好时开始回放。
    controls: false, //控制条
    preload: "auto", //视频预加载
    muted: false, //默认情况下将会消除任何音频。
    loop: true, //导致视频一结束就重新开始。
    language: "zh-CN",
    sources: [
      {
        type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
        src:
          homedata["3D"].list[0].lateralImg || homedata["3D"].list[0].phoneImg, // url地址
      },
    ],
    // hls: true,
    notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
    poster: require("@/assets/homeCase/loading.gif"),
  };

  // mounted() {
  //   this.playerImgOptions.sources[0]["src"] =
  //     this.video.lateralImg || this.video.phoneImg;
  //   if (
  //     this.$refs &&
  //     this.$refs.videoPlayer &&
  //     this.$refs.videoPlayer["player"]
  //   ) {
  //     this.$refs.videoPlayer["player"].play();
  //   }
  // }

  // @Watch("data", { immediate: true, deep: true })
  onPersonChanged(val: HomeTabContentType) {
    console.log("watch", val);
    this.video = val;
    this.playerImgOptions.sources[0]["src"] =
      this.video.lateralImg || this.video.phoneImg;
  }
}
