import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/Homepage/HomeView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/enginedemo",
    name: "enginedemo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/EngineDemo/index.vue"),
  },
  {
    path: "/enginedynamics",
    name: "enginedynamics",
    component: () => import("../views/EngineDynamics/index.vue"),
  },
  {
    path: "/enginedynamicsDetail/:id",
    name: "enginedynamicsDetail",
    component: () => import("../views/DynamicsDetail/index.vue"),
  },
  {
    path: "/productpage",
    name: "productpage",
    component: () => import("../views/ProductPage/index.vue"),
    children: [
      {
        path: "/productpage/:name",
        name: "detail",
        component: () => import("../views/ProductPage/index.vue"),
      },
    ],
  },
  {
    path: "/layafamily",
    name: "layafamily",
    component: () => import("../views/LayaFamily/index.vue"),
    children: [
      {
        path: "/layafamily/:name",
        name: "layafamilyDetail",
        component: () => import("../views/LayaFamily/index.vue"),
      },
    ],
  },
  {
    path: "/engineDownload",
    name: "engineDownload",
    component: () => import("../views/EngineDownload/index.vue"),
    children: [
      {
        path: "/engineDownload/:name",
        name: "engineDownloadDetail",
        component: () => import("../views/LayaFamily/index.vue"),
      },
    ],
  },
  {
    path: "/api",
    name: "api",
    component: () => import("../views/Api/index.vue"),
  },
  {
    path: "/doc",
    name: "doc",
    component: () => import("../views/Doc/index.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("../views/Demo/index.vue"),
  },
  {
    path: "/tradeMark",
    name: "tradeMark",
    component: () => import("../views/TradeMark/index.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
