const homedata = {
  "3D": {
    title: "3D案例",
    list: [
      {
        bigImg: require("@/assets/homeCase/img/蓝亚宇宙.jpg"),
        lateralImg: require("@/assets/homeCase/media/蓝亚宇宙.mp4"),
        phoneImg: "",
        subSrc: require("@/assets/homeCase/img/蓝亚宇宙.jpg"),
        title: "蓝亚宇宙",
        txt: "蓝亚宇宙是基于LayaAir引擎研发的元宇宙平台，该平台拥有众多商业级别元宇宙成功案例，例如《云上国漫城》、《南开大学数字元空间》、《国家电网廉政展厅元宇宙空间》、《工信部工业元宇宙协同发展组织元宇宙空间》等等。",
        code: require("@/assets/homeCase/img/蓝亚宇宙.png"),
      },
      {
        bigImg: require("@/assets/homeCase/img/穿越火线-枪战王者.jpg"),
        lateralImg: require("@/assets/homeCase/media/穿越火线-枪战王者.mp4"),
        phoneImg: "",
        subSrc: require("@/assets/homeCase/img/穿越火线-枪战王者.jpg"),
        title: "穿越火线-枪战王者",
        txt: "穿越火线（Cross Fire，简称CF）是经典的知名游戏IP，由腾讯采用LayaAir引擎研发，2020年移植到微信小游戏平台的CF正版FPS手游。",
        code: require("@/assets/homeCase/img/穿越火线-枪战王者.png"),
      },
      {
        bigImg: require("@/assets/homeCase/img/王者别嚣张.jpg"),
        lateralImg: require("@/assets/homeCase/media/王者别嚣张.mp4"),
        phoneImg: "",
        subSrc: require("@/assets/homeCase/img/王者别嚣张.jpg"),
        title: "王者别嚣张",
        txt: "王者别嚣张是一款基于LayaAir引擎的3D MOBA竞技对决游戏。公平对战、5V5王者峡谷，精彩的对局玩法，爽快的竞技过程，极速匹配为你找寻实力相当的对手同场竞技，享受刺激精彩的冒险挑战，还原MOBA经典体验！",
        code: require("@/assets/homeCase/img/王者别嚣张.png"),
      },
      {
        bigImg: require("@/assets/homeCase/img/跳舞的线.jpg"),
        phoneImg: require("@/assets/homeCase/media/跳舞的线.mp4"),
        lateralImg: "",
        subSrc: require("@/assets/homeCase/img/跳舞的线.jpg"),
        title: "跳舞的线",
        txt: "跳舞的线小游戏版，是一款基于LayaAir引擎的集快节奏操控与精选音轨于一身的节奏敏捷游戏。玩家不仅需要双眼来观察障碍物与陷阱，更需用双耳聆听节奏，旋律与节奏会帮助玩家走得更远！",
        code: require("@/assets/homeCase/img/跳舞的线.png"),
      },
      {
        bigImg: require("@/assets/homeCase/img/第7装甲师.jpg"),
        lateralImg: require("@/assets/homeCase/media/第7装甲师.mp4"),
        phoneImg: "",
        subSrc: require("@/assets/homeCase/img/第7装甲师.jpg"),
        title: "第7装甲师",
        txt: "《第7装甲师》小游戏版，采用LayaAir引擎研发，高度还原现代战争，逼真至极！酷炫合击战斗画面，全屏燃爆！畅快改装，任意设计自己的坦克，加入PVP多人战场团战，战力飙升傲视全服！",
        code: require("@/assets/homeCase/img/第7装甲师.png"),
      },
    ],
  },
  "2D": {
    title: "2D案例",
    list: [
      {
        bigImg: require("@/assets/homeCase/img/消灭病毒.jpg"),
        phoneImg: require("@/assets/homeCase/media/消灭病毒.mp4"),
        lateralImg: "",
        subSrc: require("@/assets/homeCase/img/消灭病毒.jpg"),
        title: "消灭病毒",
        txt: "消灭病毒是由青岛蓝飞互娱采用LayaAir引擎制作的爆款产品，游戏的灵感源自一部叫《工作细胞》的动画。玩家只需要控制飞机移动并击破屏幕上方不停分裂的数字“病毒”即可。上手简单趣味无穷，火爆各平台。",
        code: require("@/assets/homeCase/img/消灭病毒.png"),
      },
      {
        bigImg: require("@/assets/homeCase/img/大天使之剑H5.jpg"),
        phoneImg: require("@/assets/homeCase/media/大天使之剑H5.mp4"),
        lateralImg: "",
        subSrc: require("@/assets/homeCase/img/大天使之剑H5.jpg"),
        title: "大天使之剑H5",
        txt: "奇迹MU正版授权魔幻大作《大天使之剑》，三七游戏采用LayaAir引擎研发，月流水破亿人民币的爆款产品。完美传承暗黑魔幻玩法，新增野外探险，1人控团杀BOSS等玩法。",
        code: require("@/assets/homeCase/img/大天使之剑H5.png"),
      },
      {
        bigImg: require("@/assets/homeCase/img/格斗战机.jpg"),
        lateralImg: require("@/assets/homeCase/media/格斗战机.mp4"),
        phoneImg: "",
        subSrc: require("@/assets/homeCase/img/格斗战机.jpg"),
        title: "格斗战机",
        txt: "格斗战机是一款基于LayaAir引擎研发的二战题材空战射击游戏。该作一改传统飞机游戏的竖版躲子弹模式，让飞机在各方向都能够自由飞行，通过咬尾攻击，达到真实空战的效果，同时实现了“大筋斗”、破“S”机动，上升回旋、急转弯 等空中格斗机动动作。",
        code: require("@/assets/homeCase/img/格斗战机.png"),
      },
      {
        bigImg: require("@/assets/homeCase/img/全民大乐斗.jpg"),
        lateralImg: require("@/assets/homeCase/media/全民大乐斗.mp4"),
        phoneImg: "",
        subSrc: require("@/assets/homeCase/img/全民大乐斗.jpg"),
        title: "全民大乐斗",
        txt: "全民大乐斗是腾讯魔镜工作室采用LayaAir引擎自研的回合制游戏。Q版卡通题材，玩家代表一只胸怀武林梦想的企鹅，经历各种洗礼和挑战最终实现自己的武林梦想。",
        code: require("@/assets/homeCase/img/全民大乐斗.png"),
      },
      {
        bigImg: require("@/assets/homeCase/img/全民疯狂战机.jpg"),
        phoneImg: require("@/assets/homeCase/media/全民疯狂战机.mp4"),
        lateralImg: "",
        subSrc: require("@/assets/homeCase/img/全民疯狂战机.jpg"),
        title: "全民疯狂战机",
        txt: "全民疯狂战机采用LayaAir引擎研发，是一款应用了部分3D引擎技术的2D飞行射击游戏，完美的3D效果与2D体验混合，打造出华丽弹幕、炫酷特效的视觉盛宴！",
        code: require("@/assets/homeCase/img/全民疯狂战机.png"),
      },
    ],
  },
};
export default homedata;
