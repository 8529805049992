import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import VideoPlayer from "vue-video-player";
import { localRead } from "@/utils/index";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);
Vue.config.productionTip = false;

import VueI18n from "vue-i18n";
// 引入自定义中文包
import customZH from "./locale/zh";

// 引入自定义英文包
import customEN from "./locale/en";

Vue.use(VueI18n);

const messages = {
  en: customEN,
  zh: customZH,
};

const navLang = navigator.language.substring(0, 2);
const localLang = navLang || false;
const lang = localRead("locale") || localLang || "en";

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: lang, // 设置地区
  messages, // 设置地区信息
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
