import {fetch, getFile} from "@/utils/fetch";

export const getEngineList = () => {
  return getFile({
    url: "layaair/log/3.0/navConfig.json",
    method: "get",
    params: {
      _: Date.now(),
    },
  });
};

export const getEngineMd = (url: string) => {
  return getFile({
    url: "layaair/log/3.0/" + url,
    method: "get",
    params: {
      _: Date.now(),
    },
  });
};

export const getNewsList = (dir) => {
  return fetch({
    url: "/getFileData.php",
    method: "get",
    params: {
      url: "laya_data/news/" + dir + "navConfig.json",
      _: Date.now(),
    },
  });
};

export const getNewsMd = (url: string) => {
  return fetch({
    url: "/getFileData.php",
    method: "get",
    params: {
      url: "laya_data/news/" + url,
      _: Date.now(),
    },
  });
};
